import React from 'react';
import classNames from 'classnames/bind';
import { useToggle } from '@mantine/hooks';

import { StreamWidget } from 'types/dashboard_widget';

import { useMqttStreamsState } from 'streams/hooks/useMqttStreamsState';
import { useStream } from 'streams/hooks/useStream';
import { useWidgetModal } from 'dashboards/components/WidgetModal';

import {
  ContextMenu,
  ContextMenuAction,
} from 'components/ContextMenu/ContextMenu';
import { FilesSearchDrawer } from 'files/components/FilesSearchDrawer/FilesSearchDrawer';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { VideoSourceSnapshot } from 'components/VideoSourceSnapshot/VideoSourceSnapshot';
import { WebRTCPlayer } from 'components/WebRTCPlayer/WebRTCPlayer';

import { DashboardWidgetProps } from '../../types/widget_props';
import styles from './Stream.module.scss';

const c = classNames.bind(styles);

export function DashboardStream({
  id,
  type,
  streamId,
  name,
  autoPlay,
  onDelete,
  onDuplicate,
}: DashboardWidgetProps<StreamWidget>) {
  const { editWidget } = useWidgetModal();

  const { data: stream } = useStream(streamId);

  useMqttStreamsState([streamId]);

  const [isFileSearchDrawerOpen, setIsFilesSearchDrawerOpen] = useToggle();

  if (type !== 'stream' || !streamId) {
    return null;
  }

  function handleEditClick() {
    editWidget(id);
  }

  function handleDeleteClick() {
    onDelete(id);
  }

  function handleDuplicateClick() {
    onDuplicate(id);
  }

  const isFileSearchEnabled =
    stream &&
    // We don't offer file search drawer for pipeline output streams because files are associated with the input stream
    stream.source !== 'pipeline_stream';

  return (
    <div className={c('wrap')}>
      <div className={c('header', 'theme dark')}>
        <Heading level={3}>
          <span>{name}</span>
        </Heading>
        <ContextMenu
          trigger={
            <IconButton
              icon="more-vertical"
              label="Reveal more actions"
              variant="ghost"
              size="small"
            />
          }
        >
          <ContextMenuAction icon="edit" onClick={handleEditClick}>
            Edit
          </ContextMenuAction>
          <ContextMenuAction icon="copy" onClick={handleDuplicateClick}>
            Duplicate
          </ContextMenuAction>
          {isFileSearchEnabled && (
            <ContextMenuAction
              icon="search"
              onClick={setIsFilesSearchDrawerOpen}
            >
              Search recordings
            </ContextMenuAction>
          )}
          <ContextMenuAction
            icon="delete"
            intent="danger"
            onClick={handleDeleteClick}
          >
            Delete
          </ContextMenuAction>
        </ContextMenu>
      </div>
      {autoPlay ? (
        <WebRTCPlayer className={c('stream-player')} streamID={streamId} auto />
      ) : (
        <VideoSourceSnapshot source={stream} compact hideTitle autoRefresh />
      )}

      {isFileSearchDrawerOpen && (
        <FilesSearchDrawer
          open={isFileSearchDrawerOpen}
          onClose={setIsFilesSearchDrawerOpen}
          initialStreamId={streamId}
        />
      )}
    </div>
  );
}
