import { useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Tag from 'types/tag';

import { useAPI } from 'hooks/api/useAPI';
import {
  useBillingMutation,
  UseBillingMutationOptions,
} from 'hooks/api/useBillingMutation';
import {
  updateQueriesData,
  UpdateQueriesDataContext,
} from 'services/update_queries_data';

export type UseUpdateTagOptions = Omit<
  UseBillingMutationOptions<Tag, APIError, Tag>,
  'action' | 'entity'
>;

export function useUpdateTag({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseUpdateTagOptions = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useBillingMutation<Tag, APIError, Tag, UpdateQueriesDataContext<Tag>>(
    api.tags.update,
    {
      ...options,
      action: 'update',
      entity: 'tag',
      async onMutate(tag) {
        await queryClient.cancelQueries(['tags']);
        onMutate?.(tag);

        return updateQueriesData({
          queryClient,
          listQueryKey: ['tags'],
          singleQueryKey: ['tag'],
          ids: [tag.id],
          updateData: tag,
        });
      },
      async onError(error, tag, context) {
        onError?.(error, tag, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      async onSettled(...args) {
        queryClient.invalidateQueries(['tags']);
        onSettled?.(...args);
      },
    }
  );
}
