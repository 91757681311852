import { useLocation } from 'react-router-dom';

import { useTag } from './useTag';

export function useSelectedTag() {
  const { search } = useLocation();

  const selectedTagID = new URLSearchParams(search).get('tag');

  const queryResult = useTag(selectedTagID, {
    enabled: Boolean(selectedTagID),
    keepPreviousData: false,
  });

  return {
    ...queryResult,
    hasSelectedTag: Boolean(selectedTagID),
  };
}
