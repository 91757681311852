import {
  AlertDialog,
  AlertDialogProps,
} from 'components/AlertDialog/AlertDialog';
import { Button } from 'components/Button/Button';

export type DeleteConfirmationDialogProps = Omit<AlertDialogProps, 'title'> & {
  title?: AlertDialogProps['title'];
  entity: string;
  onConfirm: () => void;
  loading?: boolean;
};

export function DeleteConfirmationDialog({
  entity,
  onConfirm,
  loading,
  title,
  ...props
}: DeleteConfirmationDialogProps) {
  const confirmButton = (
    <Button
      onClick={onConfirm}
      intent="danger"
      variant="primary"
      loading={loading}
      autoFocus
    >
      Yes, delete
    </Button>
  );

  return (
    <AlertDialog
      {...(props as any)}
      title={
        title || `Are you sure you want to delete this ${entity || 'resource'}?`
      }
      confirmButton={confirmButton}
    />
  );
}
