import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
  QueryKey,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Tag from 'types/tag';

import { APIObject } from 'services/api';
import {
  updateQueriesData,
  UpdateQueriesDataContext,
} from 'services/update_queries_data';

import { useAPI } from 'hooks/api/useAPI';
import { TaggableEntityObject } from 'hooks/useEntities';

export type UseAddEntityTagsOptions = {
  entity: keyof APIObject;
  listQueryKey?: QueryKey;
  singleQueryKey?: QueryKey;
};

export type UseAddEntityTagsParameters<T extends TaggableEntityObject> = {
  id: T['id'];
  tagIds: Tag['id'][];
};

export function useAddEntityTags<T extends TaggableEntityObject>(
  { entity, listQueryKey = [entity], singleQueryKey }: UseAddEntityTagsOptions,
  {
    onMutate,
    onError,
    onSettled,
    ...options
  }: UseMutationOptions<
    Tag['id'][],
    APIError,
    UseAddEntityTagsParameters<T>,
    UpdateQueriesDataContext<T>
  > = {}
) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, tagIds }: UseAddEntityTagsParameters<T>) => {
      const entityAPI = api[entity];

      if (!id) {
        return Promise.reject(`Missing id`);
      }

      if (
        !entityAPI ||
        typeof entityAPI !== 'object' ||
        !('addTags' in entityAPI) ||
        !entityAPI.addTags
      ) {
        return Promise.reject(`Invalid entity type "${entity}".`);
      }

      return entityAPI.addTags(id, tagIds);
    },
    {
      ...options,
      async onMutate({ id, tagIds }) {
        await queryClient.cancelQueries(listQueryKey);
        await queryClient.cancelQueries(singleQueryKey);
        onMutate?.({ id, tagIds });

        return updateQueriesData<T>({
          queryClient,
          listQueryKey,
          singleQueryKey,
          ids: [id],
          updateData(entity) {
            const combined = [...tagIds, ...entity.tags];
            const unique = new Set(combined);
            entity.tags = [...unique];
            return entity;
          },
        });
      },
      onError(error, updatedStream, context) {
        onError?.(error, updatedStream, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      onSettled(...args) {
        onSettled?.(...args);
        queryClient.invalidateQueries(listQueryKey);
        queryClient.invalidateQueries(singleQueryKey);
      },
    }
  );
}
