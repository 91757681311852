import classnames from 'classnames/bind';

import { AppLink, AppLinkProps } from 'components/AppLink/AppLink';

import styles from './TextAppLink.module.scss';

const c = classnames.bind(styles);

/** @deprecated */
export function TextAppLink({
  to,
  children,
  className,
  title,
  keepSearchParams,
}: AppLinkProps) {
  return (
    <AppLink
      to={to}
      className={c('link', className)}
      title={title}
      keepSearchParams={keepSearchParams}
    >
      {children}
    </AppLink>
  );
}
