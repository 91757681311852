import React from 'react';
import classNames from 'classnames/bind';
import { Skeleton, Text } from '@mantine/core';

import { FileCloudStatus } from 'types/file';

import { browserSupportsVideoType } from 'services/browser_supports_video_type';

import { Icon } from 'components/Icon/Icon';

import styles from './FileDetail.module.scss';

const c = classNames.bind(styles);

export type FileDetailPreviewProps = {
  url?: string;
  mimeType?: string;
  cloudStatus?: FileCloudStatus;
  isLoading: boolean;
};

export function FileDetailPreview({
  url,
  mimeType,
  cloudStatus,
  isLoading,
}: FileDetailPreviewProps) {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);

  if (isLoading) {
    return (
      <Skeleton radius={0}>
        <div className={c('preview', 'blank')} />
      </Skeleton>
    );
  }

  if (cloudStatus === 'uploading') {
    return (
      <div className={c('preview', 'blank')}>
        <Icon name="upload" size="large" />
        <Text>File is being uploaded...</Text>
      </div>
    );
  }

  if (!url || !mimeType) {
    return (
      <div className={c('preview', 'blank')}>
        <Icon name="error" className="theme danger" size="large" />
        <Text>Unable to load media.</Text>
      </div>
    );
  }

  if (mimeType?.includes('video') && browserSupportsVideoType(mimeType)) {
    return (
      <div className={c('preview')}>
        <video controls muted>
          <source src={url} type={mimeType} />
          Failed to load the video.
        </video>
      </div>
    );
  }

  if (mimeType?.includes('image')) {
    return (
      <Skeleton visible={!isImageLoaded}>
        <div className={c('preview')}>
          <img src={url} alt="" onLoad={() => setIsImageLoaded(true)} />
        </div>
      </Skeleton>
    );
  }

  return (
    <div className={c('preview', 'blank')}>
      <Icon name="error" className="theme danger" size="large" />
      <Text>Unsupported file type {mimeType}.</Text>
    </div>
  );
}
