import React from 'react';
import classNames from 'classnames/bind';

import Tag from 'types/tag';

import { TaggableEntityObject } from 'hooks/useEntities';
import { useAddEntityTags } from 'tags/hooks/useAddEntityTag';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useDeleteEntityTags } from 'tags/hooks/useDeleteEntityTags';
import { useHasAccess } from 'hooks/useHasAccess';

import AIModel from 'types/ai_model';
import Camera from 'types/camera';
import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Stream from 'types/stream';

import { Button } from 'components/Button/Button';
import { TagSelect } from 'tags/components/TagSelect/TagSelect';

import { APIObject } from 'services/api';
import styles from './DetailViewHeader.module.scss';
import { DetailViewHeaderProps } from './DetailViewHeader';

const c = classNames.bind(styles);

type DetailViewTagsProps<
  T extends Gateway | Camera | AIModel | Deployment | Stream,
> = Pick<
  DetailViewHeaderProps<T>,
  'entity' | 'entityType' | 'listQueryKey' | 'singleQueryKey'
>;

export function DetailViewTags<
  T extends Gateway | Camera | AIModel | Deployment | Stream,
>({
  entity,
  entityType,
  singleQueryKey,
  listQueryKey,
}: DetailViewTagsProps<T>) {
  const [hasAccess] = useHasAccess();

  const { data: currentPlan, isSuccess } = useCurrentPlan();
  const canCreateTags =
    (isSuccess && currentPlan === null) ||
    Boolean(currentPlan?.can_create_tags);

  const { id } = entity;
  const canTag = entity && 'tags' in entity;

  const { mutate: addTags } = useAddEntityTags({
    entity: entityType as keyof APIObject,
    listQueryKey,
    singleQueryKey,
  });
  const { mutate: removeTags } = useDeleteEntityTags({
    entity: entityType as keyof APIObject,
    listQueryKey,
    singleQueryKey,
  });

  const canEditTags = hasAccess('deploy_edit', entityType);

  const handleAdd = React.useCallback(
    (tag: Tag) => {
      addTags({ id, tagIds: [tag.id] });
    },
    [id, addTags]
  );

  const handleRemove = React.useCallback(
    (tagId: Tag['id']) => {
      removeTags({ id, tagIds: [tagId] });
    },
    [id, removeTags]
  );

  if (!canTag || !canCreateTags) {
    return null;
  }

  const { tags } = entity as TaggableEntityObject;

  return (
    <TagSelect
      id={`detail_${entity.id}_tags`}
      className={c('tags')}
      defaultSelection={tags}
      trigger={
        <Button size="xsmall" variant="secondary">
          Edit labels
        </Button>
      }
      onAdd={handleAdd}
      onRemove={handleRemove}
      readOnly={!canEditTags}
    />
  );
}
