import React from 'react';
import classNames from 'classnames/bind';
import { useForm } from 'react-hook-form';
import { deserialize } from 'serializr';

import Tag from 'types/tag';

import { truncateMiddle } from 'services/string';
import { useCreateTag } from 'tags/hooks/useCreateTag';
import { useSelectedTag } from 'tags/hooks/useSelectedTag';

import { Button } from 'components/Button/Button';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { Input } from 'components/Input/Input';
import { LightSwitch } from 'components/LightSwitch/LightSwitch';
import { Text } from 'components/Text/Text';
import * as Dialog from 'components/Dialog';

import styles from './CreateTagDialog.module.scss';

const c = classNames.bind(styles);

type CreateTagFieldValues = {
  name: string;
};

export function CreateTagDialog() {
  const [isOpen, setIsOpen] = React.useState(false);

  const { data: selectedTag, hasSelectedTag, isLoading } = useSelectedTag();
  const [shouldCreateChildTag, setShouldCreateChildTag] = React.useState(false);
  const canCreateChild = hasSelectedTag && selectedTag;

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<CreateTagFieldValues>();

  const {
    isLoading: isCreatingTag,
    error,
    mutate: createTag,
  } = useCreateTag({
    onSuccess() {
      setIsOpen(false);
      reset();
    },
  });

  function handleOpenChange(open: boolean) {
    reset();
    setIsOpen(open);
    setShouldCreateChildTag(false);
  }

  function onSubmit({ name }: CreateTagFieldValues) {
    const tag = deserialize(Tag, {
      name,
      parent:
        selectedTag?.id && shouldCreateChildTag ? selectedTag.id : undefined,
    });

    createTag(tag);
  }

  return (
    <Dialog.Root
      className={c('dialog')}
      trigger={
        <IconButton
          id="sidebar-labels-create"
          label="Create label"
          icon="plus"
          size="small"
        />
      }
      open={isOpen}
      onOpenChange={handleOpenChange}
    >
      <Dialog.Title asChild>
        <Heading level="2">Create new label</Heading>
      </Dialog.Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormErrorMessage error={error} />

        <Field id="label-name" label="Label name" error={errors.name} required>
          <Input
            {...register('name', { required: 'Please enter a label name.' })}
            id="label-name"
            type="text"
            autoComplete="off"
            spellCheck="false"
            autoFocus
          />
        </Field>

        <div className={c('controls', { 'child-toggle': canCreateChild })}>
          {canCreateChild && (
            <div className={c('toggle')}>
              <LightSwitch
                id="create-tag_as-child-tag"
                checked={shouldCreateChildTag}
                onValueChange={setShouldCreateChildTag}
              />
              <Text className={c('toggle-info')} asChild>
                <label htmlFor="create-tag_as-child-tag">
                  Create as sub label of{' '}
                  {isLoading ? (
                    <span className="skeleton-text">Loading label...</span>
                  ) : (
                    <strong>{truncateMiddle(selectedTag.name, 30)}</strong>
                  )}
                </label>
              </Text>
            </div>
          )}

          <div className={c('button-group')} role="group">
            <Dialog.Close asChild>
              <Button variant="secondary">Cancel</Button>
            </Dialog.Close>
            <Button variant="primary" type="submit" loading={isCreatingTag}>
              Create
            </Button>
          </div>
        </div>
      </form>
    </Dialog.Root>
  );
}
