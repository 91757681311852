import React from 'react';
import * as RadixAlertDialog from '@radix-ui/react-alert-dialog';
import classNames from 'classnames/bind';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';

import { DeleteConfirmationDialog } from './DeleteConfirmationDialog';
import styles from './AlertDialog.module.scss';

const c = classNames.bind(styles);

export type AlertDialogProps = RadixAlertDialog.AlertDialogProps & {
  title: React.ReactElement | React.ReactNode | React.ReactText;
  description?: React.ReactElement | React.ReactText;
  confirmButton?: React.ReactNode;
  cancelButton?: React.ReactNode;
  intent?: 'danger' | 'warning';

  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (newState: boolean) => void;
};

const defaultDialogCancelButton = <Button variant="ghost">Cancel</Button>;

const defaultDialogConfirmButton = <Button variant="primary">Ok</Button>;

export function AlertDialog({
  title,
  description,
  children,
  confirmButton = defaultDialogConfirmButton,
  cancelButton = defaultDialogCancelButton,
  intent,
  ...rootProps
}: AlertDialogProps) {
  return (
    <RadixAlertDialog.Root {...rootProps}>
      <RadixAlertDialog.Portal>
        <RadixAlertDialog.Overlay className={c('overlay')} />
        <RadixAlertDialog.Content className={c('dialog-container')}>
          <div className={c('dialog')}>
            <div className={c('header')}>
              <RadixAlertDialog.Title className={c('header-title')}>
                {intent === 'warning' && (
                  <Icon
                    className={c('header-icon', `theme ${intent}`)}
                    name="warning"
                    size="large"
                  />
                )}
                <Heading className={c('slot')} level="2" asChild>
                  <span>{title}</span>
                </Heading>
              </RadixAlertDialog.Title>
              <RadixAlertDialog.Cancel asChild>
                <div className={c('close-button')}>
                  <IconButton label="Close" icon="cancel" variant="ghost" />
                </div>
              </RadixAlertDialog.Cancel>
            </div>
            {description && (
              <RadixAlertDialog.Description
                className={c('description', 'slot')}
              >
                {description}
              </RadixAlertDialog.Description>
            )}
            {children && <p className={c('content', 'slot')}>{children}</p>}

            <div className={c('slot')}>
              <ButtonGroup align="center">
                <RadixAlertDialog.Cancel asChild>
                  {cancelButton}
                </RadixAlertDialog.Cancel>
                <RadixAlertDialog.Cancel asChild>
                  {confirmButton}
                </RadixAlertDialog.Cancel>
              </ButtonGroup>
            </div>
          </div>
        </RadixAlertDialog.Content>
      </RadixAlertDialog.Portal>
    </RadixAlertDialog.Root>
  );
}

export { DeleteConfirmationDialog };
