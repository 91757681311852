import {
  createModelSchema,
  deserialize,
  identifier,
  list,
  primitive,
  serialize,
} from 'serializr';
import { anyObject } from 'services/serializr';

import Application from './application';
import Gateway from './gateway';
import Pipeline from './pipeline';
import Tag from './tag';

export const DEPLOYMENT_ACTIVE_STATES = [
  'deploying',
  'running',
  'restart_waiting',
] as const;
export type RunningDeploymentState = (typeof DEPLOYMENT_ACTIVE_STATES)[number];

export const DEPLOYMENT_INACTIVE_STATES = ['stopped'] as const;
export type StoppedDeploymentState =
  (typeof DEPLOYMENT_INACTIVE_STATES)[number];

export const DEPLOYMENT_ERROR_STATES = [
  'error',
  'interrupted',
  'unknown',
] as const;
export type ErrorDeploymentState = (typeof DEPLOYMENT_ERROR_STATES)[number];

export type DeploymentState =
  | RunningDeploymentState
  | StoppedDeploymentState
  | ErrorDeploymentState;

export type DeploymentProperties = {
  [property: string]: any;
};

export type DeploymentConfiguration = {
  [nodeID: string]: DeploymentProperties;
};

export default class Deployment {
  id: string;
  name?: string;
  application_id: Application['id'];
  pipeline_id: Pipeline['id'];
  gateway_id: Gateway['id'];
  state: DeploymentState;
  state_details: string | null;
  configuration: DeploymentConfiguration;
  tags: Tag['id'][];

  /**
   * ISO date string
   */
  created_at: string;

  /**
   * ISO date string
   */
  updated_at: string;

  constructor(
    id: string,
    applicationID: Application['id'],
    pipelineID: Pipeline['id'],
    gatewayID: Gateway['id'],
    name?: string,
    configuration: DeploymentConfiguration = {}
  ) {
    const now = new Date().toISOString();

    this.id = id;
    this.application_id = applicationID;
    this.pipeline_id = pipelineID;
    this.gateway_id = gatewayID;
    this.state = 'stopped';
    this.state_details = null;
    this.configuration = configuration;
    this.created_at = now;
    this.updated_at = now;
    this.tags = [];

    if (name) {
      this.name = name;
    }
  }

  get isActive() {
    return ([...DEPLOYMENT_ACTIVE_STATES] as string[]).includes(this.state);
  }

  get action() {
    if (this.isActive) {
      return 'stop';
    }

    return 'start';
  }

  copy() {
    return deserialize(Deployment, serialize(this)) as unknown as Deployment;
  }

  withName(name: string) {
    const copy = this.copy();
    copy.name = name;
    return copy;
  }
}

createModelSchema(Deployment, {
  id: identifier(),
  name: identifier(),
  application_id: primitive(),
  pipeline_id: primitive(),
  gateway_id: primitive(),
  state: primitive(),
  state_details: primitive(),
  created_at: primitive(),
  updated_at: primitive(),
  configuration: anyObject(),
  tags: list(primitive()),
});
