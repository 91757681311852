import { createRoot } from 'react-dom/client';
import { TooltipProvider } from '@radix-ui/react-tooltip';

import {
  AlertDialog,
  AlertDialogProps,
} from 'components/AlertDialog/AlertDialog';
import { Button } from 'components/Button/Button';

export type AddDeleteConfirmationDialogProps = {
  title: string;
  message?: string;
  isLoading?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
};

export type AddDialogProps = AddDeleteConfirmationDialogProps & {
  message?: string;
  intent?: AlertDialogProps['intent'];
  confirmButtonText?: string;
  cancelButtonText?: string;
};

const containerElement = document.getElementById('modal-area');

export function addDialog({
  title,
  message,
  isLoading,
  intent,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  onCancel,
  onConfirm,
}: AddDialogProps) {
  const wrapper = document.createElement('div');
  wrapper.id = title;
  containerElement?.appendChild(wrapper);

  function handleClose() {
    wrapper.remove();
    onCancel?.();
  }

  function handleConfirm() {
    onConfirm();
    handleClose();
  }

  createRoot(wrapper).render(
    <TooltipProvider>
      <AlertDialog
        title={title}
        confirmButton={
          <Button
            onClick={handleConfirm}
            intent={intent === 'warning' ? 'default' : intent}
            variant="primary"
            loading={isLoading}
            autoFocus
          >
            {confirmButtonText}
          </Button>
        }
        cancelButton={<Button variant="ghost">{cancelButtonText}</Button>}
        intent={intent}
        defaultOpen
      >
        {message}
      </AlertDialog>
    </TooltipProvider>
  );
}

export function addDeleteConfirmationDialog({
  title,
  message,
  isLoading,
  onConfirm,
  onCancel,
}: AddDeleteConfirmationDialogProps) {
  return addDialog({
    title,
    message,
    isLoading,
    intent: 'danger',
    confirmButtonText: 'Yes, delete',
    onConfirm,
    onCancel,
  });
}
