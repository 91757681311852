import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Tag from 'types/tag';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export function useTag(
  tagID: Tag['id'] | null,
  options?: UseQueryOptions<Tag, APIError>
) {
  const { tags } = useAPI();

  return useAuthenticatedQuery(
    ['tag', tagID],
    () => {
      if (!tagID) {
        return Promise.reject('No tag id provided.');
      }

      return tags.read(tagID);
    },
    {
      ...options,
      enabled: options?.enabled
        ? options.enabled && Boolean(tagID)
        : Boolean(tagID),
    }
  );
}
