import classnames from 'classnames/bind';
import React from 'react';
import { CellProps } from 'react-table';

import Gateway from 'types/gateway';
import Pipeline from 'types/pipeline';
import Stream, { StreamType } from 'types/stream';

import {
  AttributeList,
  AttributeListItem,
} from 'components/AttributeList/AttributeList';
import { Icon } from 'components/Icon/Icon';
import { MediaPreview } from 'components/MediaPreview/MediaPreview';
import { NameCell } from 'components/Table/Table';
import { TextAppLink } from 'components/TextAppLink/TextAppLink';

import styles from './Table.module.scss';

const c = classnames.bind(styles);

export type StreamsTableInfoCellProps = CellProps<Stream> & {
  isRenaming?: boolean;
  gateway?: Gateway;
  pipeline?: Pipeline;
  onCancelRename?: () => void;
  onRename?: (stream: Stream) => void;
  onRequestStream: (stream: Stream) => void;
  to?: string;
};

export function StreamsTableInfoCell({
  isRenaming: initialIsRenaming,
  gateway,
  pipeline,
  onCancelRename,
  onRename,
  onRequestStream,
  to,
  ...props
}: StreamsTableInfoCellProps) {
  const stream = props.row.original;

  const [isRenaming, setIsRenaming] = React.useState(initialIsRenaming);

  React.useEffect(() => {
    setIsRenaming(initialIsRenaming);
  }, [initialIsRenaming]);

  function handleNameChange(newName: string) {
    onRename?.(stream.withName(newName));
    setIsRenaming(false);
  }

  return (
    <div className={c('stream', stream.stream_type)}>
      {stream.stream_type === StreamType.WEBRTC && (
        <MediaPreview
          className={c('stream-thumbnail')}
          stream={stream}
          onPlay={() => onRequestStream(stream)}
        />
      )}

      <div className={c('stream-summary')}>
        <NameCell
          {...props}
          to={to}
          isRenaming={isRenaming}
          onNameChange={onRename ? handleNameChange : undefined}
          onCancel={onCancelRename}
          entity="stream"
        />

        <AttributeList className={c('stream-sources')}>
          {pipeline && pipeline.hasId() && (
            <AttributeListItem icon="pipeline" label="Pipeline">
              {pipeline.id ? (
                <TextAppLink to={`/design/pipelines/${pipeline.id}`}>
                  {pipeline.name}
                </TextAppLink>
              ) : (
                <>unknown</>
              )}
            </AttributeListItem>
          )}
          {!gateway && stream.isGlobal() && (
            <span className={c('stream-type')}>
              <Icon name="global" size="small" />
              <span>Global</span>
            </span>
          )}
        </AttributeList>
      </div>
    </div>
  );
}
