import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';
import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Node from 'types/node';
import Pipeline from 'types/pipeline';
import Stream from 'types/stream';
import { LumeoFile } from 'types/file';
import { OffsetPaginated, OffsetPaginationParams } from 'types/pagination';
import { TagParams } from 'tags/types/tagged';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export type UseFilesParams = TagParams & {
  /** ISO 8601 string */
  created_ts_since?: string;
  /** ISO 8601 string */
  created_ts_until?: string;

  camera_ids?: Camera['id'][];
  deployment_ids?: Deployment['id'][];
  file_ids?: LumeoFile['id'][];
  gateway_ids?: Gateway['id'][];
  node_ids?: Node['id'][];
  pipeline_ids?: Pipeline['id'][];
  stream_ids?: Stream['id'][];

  /** @default false */
  with_thumbnail?: boolean;
  /** @default true */
  include_snapshots?: boolean;
};

export function useFiles(
  queryKey: QueryKey = ['files'],
  {
    pagination = 'offset',
    page = 1,
    limit = 50,
    ...filters
  }: UseFilesParams & OffsetPaginationParams = {},
  options: UseQueryOptions<OffsetPaginated<LumeoFile>, APIError> = {}
) {
  const { applicationID, client } = useAPI();
  const params = {
    pagination,
    page,
    limit,
    ...filters,
  };

  return useAuthenticatedQuery(
    [...queryKey, applicationID, params],
    async () => {
      const { data } = await client.get<OffsetPaginated<LumeoFile>>(
        `/apps/${applicationID}/files`,
        {
          params,
        }
      );

      return data;
    },
    options
  );
}
