import React from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames/bind';

import { useMedia } from 'services/media_context';

import { Controls } from './Controls';
import styles from './MediaOverlay.module.scss';

const c = classnames.bind(styles);

ReactModal.setAppElement('#root');

type MediaOverlayProps = React.PropsWithChildren<{
  onRequestClose: () => void;
}>;

/** @deprecated */
export function MediaOverlay({ children, onRequestClose }: MediaOverlayProps) {
  const { file } = useMedia();

  return (
    <ReactModal
      isOpen={true}
      onRequestClose={onRequestClose}
      className={c(file ? 'full' : 'fit')}
      overlayClassName={c('mask')}
      htmlOpenClassName={c('overlay-active')}
      shouldCloseOnOverlayClick
    >
      <div className={c('content')}>
        <div className={c('media', { file })}>{children}</div>

        {file && <Controls onRequestClose={onRequestClose} />}
      </div>
    </ReactModal>
  );
}
