import classNames from 'classnames/bind';
import { filesize } from 'filesize';
import { useParams } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';
import { useIntercom } from 'react-use-intercom';

import { OrganizationParams } from 'organizations/types/organization_params';

import { useHasAccess } from 'hooks/useHasAccess';
import { useOrganization } from 'hooks/useOrganization';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { usePricingPlans } from 'organizations/hooks/usePricingPlans';

import { Button } from 'components/Button/Button';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import * as List from 'components/List';
import { Loader } from 'components/Loader/Loader';
import * as PageLayout from 'components/PageLayout';
import { Pill } from 'components/Pill/Pill';
import { Text } from 'components/Text/Text';

import { UpgradePlanDialog } from 'organizations/components/UpgradePlanDialog/UpgradePlanDialog';

import styles from './ComparePlansView.module.scss';
import { ComparePlansListItem } from './ListItem';

const c = classNames.bind(styles);

const numberFormat = new Intl.NumberFormat('en-us');

function getFilesizeLabel(value: number | null) {
  if (!value) {
    return 'Unlimited';
  }

  return filesize(value, { base: 2 });
}

function getLabel(value: number | null, label: string) {
  if (!value) {
    return `Unlimited ${label}s`;
  }

  const labelStr = `${label}${value > 1 ? 's' : ''}`;

  return `${numberFormat.format(value)} ${labelStr}`;
}

export function ComparePlansView() {
  const { organizationID } = useParams<OrganizationParams>();
  const { data: organization } = useOrganization(organizationID);
  const { data: plans, isLoading: isLoadingPlans } = usePricingPlans();

  const { data: currentPlan, isLoading: isLoadingCurrentPlan } =
    useCurrentPlan();

  const [hasAccess] = useHasAccess();

  const { show } = useIntercom();

  const isLoading = isLoadingCurrentPlan || isLoadingPlans;

  const [isPaymentDialogOpen, toggleIsPaymentDialogOpen] = useToggle();

  if (!organization) {
    return null;
  }

  const starterPlan = plans?.find(
    ({ id }) => process.env.REACT_APP_STARTER_PLAN_ID === id
  );
  const businessPlan = plans?.find(
    ({ id }) => process.env.REACT_APP_BUSINESS_PLAN_ID === id
  );

  const hasStarterPlan = currentPlan?.id === starterPlan?.id;
  const hasBusinessPlan = currentPlan?.id === businessPlan?.id;
  const hasCustomPlan = !hasStarterPlan && !hasBusinessPlan;

  return (
    <PageLayout.Root>
      <div>
        <PageLayout.Header>
          <div className={c('head')}>
            <div>
              <Text>{organization.name}</Text>
              <Heading level="1">Compare plans</Heading>
            </div>
            <div>
              <Text>
                Any questions?{' '}
                <Button variant="link" onClick={show}>
                  Chat with us
                </Button>
              </Text>
            </div>
          </div>
        </PageLayout.Header>
        {!hasAccess('billing') && (
          <PageLayout.Header>
            <FormMessage icon="info" intent="warning">
              Please contact a billing user to change plans.
            </FormMessage>
          </PageLayout.Header>
        )}
        <PageLayout.Header>
          {isLoading ? (
            <Loader size="small" text="Loading pricing plans..." />
          ) : (
            <div className={c('grid', 'header')}>
              {starterPlan && (
                <div className={c('header-item', 'grid-item')}>
                  <Heading level="2">{starterPlan.name}</Heading>
                  <Text>{starterPlan.description}</Text>
                  <div className={c('header-item-pricing')}>
                    <Text className={c('header-item-pricing-value')}>
                      <em>{starterPlan.price_description}</em>
                      {hasStarterPlan && (
                        <Pill intent="info">Current plan</Pill>
                      )}
                    </Text>

                    {hasStarterPlan ? (
                      <Button variant="secondary" disabled>
                        Current plan
                      </Button>
                    ) : (
                      hasAccess('billing') && (
                        <Button variant="secondary" onClick={show}>
                          Downgrade
                        </Button>
                      )
                    )}
                  </div>
                </div>
              )}

              {businessPlan && (
                <div className={c('header-item', 'grid-item')}>
                  <Heading level="2">{businessPlan.name}</Heading>
                  <Text>{businessPlan.description}</Text>

                  <div className={c('header-item-pricing')}>
                    <Text>
                      <em>{businessPlan.price_description}</em>
                      {hasBusinessPlan && (
                        <Pill intent="info">Current plan</Pill>
                      )}
                    </Text>

                    {hasBusinessPlan && (
                      <Button variant="secondary" disabled>
                        Current plan
                      </Button>
                    )}

                    {hasStarterPlan && hasAccess('billing') && (
                      <UpgradePlanDialog
                        trigger={<Button variant="primary">Upgrade</Button>}
                        open={isPaymentDialogOpen}
                        onOpenChange={toggleIsPaymentDialogOpen}
                        onSuccess={toggleIsPaymentDialogOpen}
                        planID={businessPlan.id}
                      />
                    )}

                    {hasCustomPlan && hasAccess('billing') && (
                      <Button variant="secondary" onClick={show}>
                        Downgrade
                      </Button>
                    )}
                  </div>
                </div>
              )}

              <div className={c('header-item', 'grid-item')}>
                <Heading level="2">Custom</Heading>
                <Text>
                  Annual plans with volume discounts,
                  <br />
                  committed pricing and reseller support.
                </Text>

                {hasCustomPlan && (
                  <Text>
                    <Pill intent="info">Current plan</Pill>
                  </Text>
                )}

                {hasAccess('billing') && (
                  <div className={c('header-item-pricing')}>
                    <Button variant="primary" onClick={show}>
                      Contact sales
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </PageLayout.Header>
      </div>
      <PageLayout.Content>
        <div className={c('grid')}>
          {starterPlan && (
            <div className={c('grid-item')}>
              <Heading className={c('grid-heading')} level="4" asChild>
                <h3>Includes</h3>
              </Heading>
              <List.Root>
                <ComparePlansListItem>
                  Drag &amp; drop analytic builder with starter nodes
                </ComparePlansListItem>
                <ComparePlansListItem>
                  Use Lumeo's AI models or bring your own
                </ComparePlansListItem>
                <ComparePlansListItem>
                  Solution library &amp; templates
                </ComparePlansListItem>
                <ComparePlansListItem>Camera management</ComparePlansListItem>
                <ComparePlansListItem>
                  Bring your own gateways
                </ComparePlansListItem>
                <ComparePlansListItem>1 user</ComparePlansListItem>
                <ComparePlansListItem>
                  {getLabel(starterPlan.application_limit, 'workspace')}
                </ComparePlansListItem>
              </List.Root>

              <Heading className={c('grid-heading')} level="4">
                Usage
              </Heading>
              <List.Root>
                <ComparePlansListItem>1 gateway</ComparePlansListItem>
                <ComparePlansListItem>
                  {starterPlan.running_deployment_limit} concurrent deployments
                </ComparePlansListItem>
                <ComparePlansListItem>
                  {getLabel(
                    starterPlan.included_file_processing_minutes,
                    'minute'
                  )}{' '}
                  of recorded analytics
                </ComparePlansListItem>
                <ComparePlansListItem>
                  {getFilesizeLabel(starterPlan.included_cloud_storage_bytes)}{' '}
                  cloud storage
                </ComparePlansListItem>
              </List.Root>

              <Heading className={c('grid-heading')} level="4">
                Support
              </Heading>
              <List.Root>
                <ComparePlansListItem>
                  Full access to docs and online community
                </ComparePlansListItem>
                <ComparePlansListItem>
                  Chat and email support
                </ComparePlansListItem>
              </List.Root>
            </div>
          )}

          {businessPlan && (
            <div className={c('grid-item')}>
              <Heading className={c('grid-heading')} level="4" asChild>
                <h3>Everything in {starterPlan?.name || 'Starter'}, plus:</h3>
              </Heading>
              <List.Root>
                <ComparePlansListItem>
                  Drag &amp; drop analytic builder with core nodes
                </ComparePlansListItem>

                {/* Only show business applications if the number is larger
                than for the starter plan */}
                {Number.isFinite(starterPlan?.application_limit) &&
                (!businessPlan.application_limit ||
                  businessPlan.application_limit >
                    starterPlan?.application_limit!) ? (
                  <ComparePlansListItem>
                    {getLabel(businessPlan.application_limit, 'workspace')}
                  </ComparePlansListItem>
                ) : null}

                <ComparePlansListItem>
                  Organize with labels
                </ComparePlansListItem>
                <ComparePlansListItem>
                  Custom dashboards with 30 day data retention
                </ComparePlansListItem>
              </List.Root>

              <Heading className={c('grid-heading')} level="4">
                Usage
              </Heading>
              <List.Root>
                <ComparePlansListItem>
                  Unlimited self-hosted Gateways
                </ComparePlansListItem>
                <ComparePlansListItem>
                  Unlimited concurrent deployments
                </ComparePlansListItem>
                <ComparePlansListItem>
                  {getLabel(
                    businessPlan.included_monthly_active_live_streams,
                    'monthly-active live stream'
                  )}
                  <br />
                  <Text size="small" inline>
                    + $10 per additional stream
                  </Text>
                </ComparePlansListItem>
                <ComparePlansListItem>
                  {getLabel(
                    businessPlan.included_file_processing_minutes,
                    'minute'
                  )}{' '}
                  of recorded analytics
                  <br />
                  <Text size="small" inline>
                    + $100 per additional 10,000 minutes
                  </Text>
                </ComparePlansListItem>
                <ComparePlansListItem>
                  {getFilesizeLabel(businessPlan.included_cloud_storage_bytes)}{' '}
                  cloud storage
                  <br />
                  <Text size="small" inline>
                    + $50 per additional 1 TiB
                  </Text>
                </ComparePlansListItem>
                <ComparePlansListItem>
                  1 Lumeo Cloud Gateway (L1 tier, ~30 fps)
                  <br />
                  <Text type="paragraph" size="small" inline>
                    + $50 per additional L1 Gateway (~30 fps) <br />
                  </Text>
                  <Text type="paragraph" size="small" inline>
                    + $150 per additional L2 Gateway (~100 fps) <br />
                  </Text>
                  <Text type="paragraph" size="small" inline>
                    + $300 per additional L3 Gateway (~200 fps) <br />
                  </Text>
                </ComparePlansListItem>
              </List.Root>

              <Heading className={c('grid-heading')} level="4">
                Support
              </Heading>
              <List.Root>
                <ComparePlansListItem>
                  Priority response time
                </ComparePlansListItem>
              </List.Root>
            </div>
          )}

          <div className={c('grid-item')}>
            <Heading className={c('grid-heading')} level="4" asChild>
              <h3>Everything in {businessPlan?.name || 'Business'}, plus:</h3>
            </Heading>
            <List.Root>
              <ComparePlansListItem>
                Drag &amp; drop analytic builder with premium nodes
              </ComparePlansListItem>
              <ComparePlansListItem>Unlimited workspaces</ComparePlansListItem>
              <ComparePlansListItem>Unlimited users</ComparePlansListItem>
              <ComparePlansListItem>
                Flexible dashboard data retention options
              </ComparePlansListItem>
              {/* <ComparePlansListItem>Role-based use access control</ComparePlansListItem> */}
            </List.Root>

            <Heading className={c('grid-heading')} level="4">
              Usage
            </Heading>
            <List.Root>
              <ComparePlansListItem>
                Custom quantity of live streams, recorded analytics, and cloud
                storage
              </ComparePlansListItem>
            </List.Root>

            <Heading className={c('grid-heading')} level="4">
              Support
            </Heading>
            <List.Root>
              <ComparePlansListItem>
                24&ndash;48 hour response time
              </ComparePlansListItem>
              <ComparePlansListItem>Phone support</ComparePlansListItem>
              <ComparePlansListItem>Shared Slack channel</ComparePlansListItem>
              <ComparePlansListItem>
                Assigned account manager
              </ComparePlansListItem>
              <ComparePlansListItem>
                Professional consulting
              </ComparePlansListItem>
            </List.Root>
          </div>
        </div>
      </PageLayout.Content>
    </PageLayout.Root>
  );
}
