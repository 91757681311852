import React from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { OptionProps } from 'react-select';
import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Gateway from 'types/gateway';
import { PaginatedAPIResponse } from 'types/api';

import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import { useGateways, UseGatewaysFilterParams } from 'hooks/api/useGateways';

import { Icon } from 'components/Icon/Icon';
import { Select, SelectOption, SelectProps } from 'components/Select/Select';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { TagSelect } from 'tags/components/TagSelect/TagSelect';
import { Text } from 'components/Text/Text';

import styles from './GatewaySelect.module.scss';

const c = classNames.bind(styles);

export type GatewaySelectProps<IsMulti extends boolean = false> = SelectProps<
  Gateway,
  IsMulti
> & {
  queryFilters?: UseGatewaysFilterParams;
  queryOptions?: UseQueryOptions<PaginatedAPIResponse<Gateway>, APIError>;
};

export function GatewaySelect<IsMulti extends boolean = false>({
  placeholder = 'Gateway',
  isLoading,
  queryFilters = {},
  queryOptions = {},
  ...props
}: GatewaySelectProps<IsMulti>) {
  const [filters, setFilters] = React.useState<UseGatewaysFilterParams>({});

  const { data, isLoading: isLoadingOptions } = useGateways(
    ['gateway-select-gateways'],
    { ...queryFilters, ...filters },
    { keepPreviousData: true, ...queryOptions }
  );

  const handleInputChange = useDebouncedCallback(
    (value: string) => {
      setFilters((previousFilters) => ({
        ...previousFilters,
        gateway_names: [value],
      }));
    },
    [],
    { wait: 100 }
  );

  function Option({ data, children, ...rest }: OptionProps<Gateway, IsMulti>) {
    return (
      <SelectOption {...rest} data={data}>
        <div className={c('option')}>
          <StatusIndicator
            className={c('option-status')}
            status={data.status}
            size="small"
          />
          <Text className={c('option-name')}>
            <strong>{data.name}</strong>
            {data.is_managed && (
              <Icon
                name="cloud"
                className={c('theme primary')}
                aria-label="Lumeo Cloud Gateway"
              />
            )}
          </Text>

          {data.tags.length > 0 && (
            <div className={c('option-labels')}>
              <TagSelect
                id={`gateway-select_${data.id}`}
                defaultSelection={data.tags}
                readOnly
              />
            </div>
          )}

          {data.status === 'online' && data.stops_automatically_at && (
            <Text size="small" className={c('option-expiry')}>
              <Icon name="timer" />{' '}
              {dayjs(data.stops_automatically_at).fromNow(true)} remaining
            </Text>
          )}
        </div>
      </SelectOption>
    );
  }

  return (
    <Select
      {...props}
      options={data?.data}
      totalElements={data?.total_elements}
      onInputChange={handleInputChange}
      placeholder={placeholder}
      getOptionLabel={({ name }) => name}
      getOptionValue={({ id }) => id}
      isLoading={isLoading || isLoadingOptions}
      components={{ Option }}
    />
  );
}
