import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export function useCamera(
  cameraID?: Camera['id'] | null,
  {
    enabled,
    ...options
  }: Omit<UseQueryOptions<Camera, APIError>, 'placeholderData'> = {}
) {
  const api = useAPI();

  return useAuthenticatedQuery(
    ['camera', cameraID],
    () => {
      if (!cameraID) {
        return Promise.reject('No camera id provided.');
      }

      return api.cameras.read(cameraID);
    },
    {
      ...options,
      enabled: enabled && Boolean(cameraID),
    }
  );
}
