import React from 'react';
import classnames from 'classnames/bind';
import { useParams } from 'react-router-dom';

import { ApplicationParams } from 'application/types/application_params';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useHasAccess } from 'hooks/useHasAccess';

import { Box } from 'components/Box/Box';
import { Button } from 'components/Button/Button';
import { CreateTagDialog } from 'tags/components/CreateTagDialog/CreateTagDialog';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { ScrollArea } from 'components/ScrollArea/ScrollArea';
import { Separator } from 'components/Separator/Separator';
import { TagsTree } from 'tags/components/TagsTree/TagsTree';
import { Text } from 'components/Text/Text';

import styles from './Sidebar.module.scss';

const c = classnames.bind(styles);

export type SidebarProps = React.PropsWithChildren<{
  hasTagsTree?: boolean;
}>;

export function Sidebar({ children, hasTagsTree }: SidebarProps) {
  const [hasAccess] = useHasAccess();

  const { data: currentPlan, isSuccess } = useCurrentPlan();
  const { data: organization } = useCurrentOrganization();
  const { applicationID } = useParams<ApplicationParams>();

  const isStarterPlan =
    currentPlan?.id === process.env.REACT_APP_STARTER_PLAN_ID;

  const canCreateTags =
    (isSuccess && currentPlan === null) ||
    Boolean(currentPlan?.can_create_tags);

  const shouldDisplayTags = isSuccess && organization && hasTagsTree;
  const shouldDisplayUpgrade =
    isSuccess && organization && !hasTagsTree && isStarterPlan;

  return (
    <div className={c('nav-wrap')}>
      <ScrollArea className={c('nav-scroll')}>
        <nav className={c('nav')}>
          <ul className={c('nav-list')} id="up-sidebar-navigation">
            {children}
          </ul>

          {shouldDisplayTags && (
            <>
              <Separator />

              {canCreateTags ? (
                <>
                  <div className={c('labels-header')}>
                    <Heading level="4">Labels</Heading>
                    {canCreateTags && hasTagsTree && hasAccess('deploy') && (
                      <CreateTagDialog />
                    )}
                  </div>

                  <TagsTree id="sidebar-labels-hierarchy" />
                </>
              ) : (
                <Box
                  className={c('upgrade')}
                  size="small"
                  variant="primary"
                  theme="inverted"
                >
                  <Icon name="tag" />
                  <Text type="paragraph">
                    Organize your workspace with labels
                  </Text>
                  <Button
                    size="small"
                    variant="primary"
                    to={`/applications/${applicationID}/settings/organization/${organization.id}/plans`}
                  >
                    Compare plans
                  </Button>
                </Box>
              )}
            </>
          )}

          {shouldDisplayUpgrade && (
            <Box
              className={c('upgrade', 'default')}
              size="small"
              variant="primary"
              theme="inverted"
            >
              <Text type="paragraph">
                Supercharge your toolkit: Premium nodes, Labels, Additional
                cameras, Data Dashboards, and more.
              </Text>
              <Button
                size="small"
                variant="primary"
                to={`/applications/${applicationID}/settings/organization/${organization.id}/plans`}
              >
                Compare plans
              </Button>
            </Box>
          )}
        </nav>
      </ScrollArea>
    </div>
  );
}
