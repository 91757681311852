import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Tag from 'types/tag';

import { useAPI } from 'hooks/api/useAPI';
import { useDangerousMutation } from 'hooks/api/useDangerousMutation';
import {
  updateQueriesData,
  UpdateQueriesDataContext,
} from 'services/update_queries_data';

export function useDeleteTag({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<
  void,
  APIError,
  Tag['id'],
  UpdateQueriesDataContext<Tag> | void
> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useDangerousMutation(
    `Are you sure you want to delete this label?`,
    api.tags.delete,
    {
      ...options,
      info: 'All sublabels will also be deleted.',
      entity: 'tag',
      action: 'delete',
      async onMutate(tagID) {
        await queryClient.cancelQueries(['tags']);
        onMutate?.(tagID);

        return updateQueriesData<Tag>({
          queryClient,
          listQueryKey: ['tags'],
          singleQueryKey: ['tag'],
          ids: [tagID],
          updateData: null,
        });
      },
      onError(error, tagID, context) {
        onError?.(error, tagID, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      onSettled(...args) {
        onSettled?.(...args);
        queryClient.invalidateQueries(['tags']);
      },
    }
  );
}
