import {
  createModelSchema,
  deserialize,
  identifier,
  list,
  primitive,
  serialize,
} from 'serializr';

import { objectOrNull } from 'services/serializr';

import Tag from './tag';
import { CameraConfiguration } from './camera';
import { LumeoFile } from './file';
import { RTSPConfig } from 'types/rtsp_config';

export type StreamSource = 'uri_stream' | 'camera_stream' | 'pipeline_stream';

export enum StreamType {
  RTSP = 'rtsp',
  WEBRTC = 'webrtc',
  FILE = 'file',
}

export const STREAM_STATES = ['offline', 'online', 'unknown'] as const;

export type StreamState = (typeof STREAM_STATES)[number];

export function isStreamState(state: string): state is StreamState {
  return ([...STREAM_STATES] as string[]).includes(state);
}

export type StreamConfiguration = CameraConfiguration & {
  bitrate?: string;
  jitter?: string;
};

export default class Stream {
  source_type: 'stream' = 'stream';

  id: string;
  name: string;
  created_at: string;
  updated_at?: string;

  source?: StreamSource;
  stream_type?: StreamType;
  uri?: string;
  status: StreamState;

  application_id?: string;
  gateway_id?: string;
  deployment_id?: string;

  /** null unless source = camera_stream */
  camera_id?: string | null;

  /** Node ID from the deployment. Null unless source = pipeline_stream */
  node?: string | number;
  /** JSON string */
  configuration?: string;

  snapshot_file_id?: LumeoFile['id'] | null;

  tags: Tag['id'][];

  rtsp_config: RTSPConfig | null = null;

  constructor(id: string, name: string) {
    const now = new Date().toISOString();
    this.id = id;
    this.name = name;
    this.created_at = now;
    this.status = 'offline';
    this.tags = [];
  }

  get isOnline(): boolean {
    return this.status === 'online';
  }

  get isOffline(): boolean {
    return !this.isOnline && this.status !== 'unknown';
  }

  get isViewable() {
    const hasFileID = Boolean(this.fileID);
    const isLinkedViewable =
      this.uri &&
      !this.uri.includes('lumeo://') &&
      !this.uri.includes('file://');
    return hasFileID || Boolean(isLinkedViewable);
  }

  get isStreamable() {
    // Console Streamable streams include:
    // 1. Streams from cameras and pipelines
    // 2. URI RTSP streams that are assigned to a Gateway and are monitored (ie status is not unknown)
    const isUriRTSPStream =
      this.source === 'uri_stream' &&
      this.uri?.includes('rtsp://') &&
      this.gateway_id;
    return (
      this.source === 'camera_stream' ||
      this.source === 'pipeline_stream' ||
      (isUriRTSPStream && this.status !== 'unknown')
    );
  }

  get isH264Stream() {
    return (
      this.name.toLowerCase().includes('h264') ||
      this.name.toLowerCase().includes('h.264') ||
      this.isWebRTCStream()
    );
  }

  get canTakeSnapshot() {
    return (
      (this.source === 'camera_stream' && this.isOnline) ||
      this.source === 'uri_stream' ||
      (this.source === 'pipeline_stream' &&
        this.stream_type === StreamType.RTSP)
    );
  }

  static fromFile(file: LumeoFile): Stream {
    const stream = new Stream('', file.name);
    stream.stream_type = StreamType.FILE;
    stream.source = 'uri_stream';
    stream.uri = `lumeo://${file.id}`;
    return stream;
  }

  isWebRTCStream(): boolean {
    return (
      this.source === 'pipeline_stream' &&
      this.stream_type === StreamType.WEBRTC
    );
  }

  isFileStream(): boolean {
    return this.stream_type === StreamType.FILE;
  }

  isGlobal(): boolean {
    return !this.gateway_id;
  }

  copy(): Stream {
    return deserialize(Stream, serialize(this)) as unknown as Stream;
  }

  get config(): StreamConfiguration {
    if (!this.configuration) {
      return {};
    }

    return JSON.parse(this.configuration);
  }

  get bitrate() {
    return this.config.bitrate;
  }

  get resolution() {
    return this.config.resolution;
  }

  get format() {
    return this.config.format;
  }

  get framerate() {
    return this.config.framerate;
  }

  get fileID(): LumeoFile['id'] | undefined {
    if (!this.isFileStream() || !this.uri?.includes('lumeo://')) {
      return undefined;
    }

    return this.uri?.replace('lumeo://', '');
  }

  withName(name: string): Stream {
    const newStream = this.copy();
    newStream.name = name;
    return newStream;
  }
}

createModelSchema(Stream, {
  id: identifier(),
  created_at: primitive(),
  updated_at: primitive(),
  application_id: primitive(),
  name: primitive(),
  source: primitive(),
  stream_type: primitive(),
  gateway_id: primitive(),
  uri: primitive(),
  status: primitive(),
  camera_id: primitive(),
  deployment_id: primitive(),
  node: primitive(),
  snapshot_file_id: primitive(),
  configuration: primitive(),
  tags: list(primitive()),
  rtsp_config: objectOrNull(RTSPConfig),
});
