import React from 'react';
import classnames from 'classnames/bind';
import { Column, CellProps } from 'react-table';

import { FilesWidget } from 'types/dashboard_widget';
import { LumeoFile } from 'types/file';

import { WIDGET_REFETCH_INTERVAL } from 'dashboards/services/interval';
import { useCameras } from 'cameras/hooks/useCameras';
import { useDeployments } from 'hooks/api/useDeployments';
import { useFiles } from 'files/hooks/useFiles';
import { usePipelines } from 'pipelines/hooks/usePipelines';
import { useStreams } from 'streams/hooks/useStreams';
import { useTimeRangeParams } from 'dashboards/hooks/useTimeRangeParams';
import { useWidgetModal } from 'dashboards/components/WidgetModal';

import {
  ContextMenu,
  ContextMenuAction,
} from 'components/ContextMenu/ContextMenu';
import { Heading } from 'components/Heading/Heading';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { IconButton } from 'components/IconButton/IconButton';
import { PaginatedTable } from 'components/Table/PaginatedTable';
import { ScrollArea } from 'components/ScrollArea/ScrollArea';

import { DashboardWidgetProps } from '../../types/widget_props';
import { DashboardFilesElement } from './Element';
import styles from './Files.module.scss';

const c = classnames.bind(styles);

export function DashboardFiles({
  id,
  options,
  name,
  onDelete,
  onDuplicate,
}: DashboardWidgetProps<FilesWidget>) {
  const { editWidget } = useWidgetModal();
  const { start, stop } = useTimeRangeParams();

  const [page, setPage] = React.useState(1);
  const limit = 10;

  const queryResult = useFiles(
    ['files'],
    {
      page,
      limit: 10,
      ...(options ?? {}),
      created_ts_since: start,
      created_ts_until: stop,
    },
    { refetchInterval: WIDGET_REFETCH_INTERVAL, keepPreviousData: true }
  );

  const deploymentIds = [
    ...new Set(
      queryResult.data?.data
        .map(({ deployment_id }) => deployment_id)
        .filter(isNotEmpty)
    ),
  ];

  const pipelineIds = [
    ...new Set(
      queryResult.data?.data.map((file) => file.pipeline_id).filter(isNotEmpty)
    ),
  ];

  const cameraIds = [
    ...new Set(
      queryResult.data?.data.map((file) => file.camera_id).filter(isNotEmpty)
    ),
  ];

  const streamIds = [
    ...new Set(
      queryResult.data?.data.map((file) => file.stream_id).filter(isNotEmpty)
    ),
  ];

  const deploymentQueryResult = useDeployments(
    ['dashboard-files-deployments'],
    {
      deployment_ids: deploymentIds,
      limit: deploymentIds.length,
    },
    { enabled: deploymentIds.length > 0 }
  );

  const pipelineQueryResult = usePipelines(
    ['dashboard-files-pipelines'],
    {
      pipeline_ids: pipelineIds,
      limit: pipelineIds.length,
    },
    { enabled: pipelineIds.length > 0 }
  );

  // TODO(dustin): use the videosource endpoint that is available on api-server to reduce this in to one call
  const cameraQueryResult = useCameras(
    ['dashboard-files-cameras'],
    {
      camera_ids: cameraIds,
      limit: cameraIds.length,
    },
    { enabled: cameraIds.length > 0 }
  );

  const streamQueryResult = useStreams(
    ['dashboard-files-streams'],
    {
      stream_ids: streamIds,
      limit: streamIds.length,
    },
    { enabled: streamIds.length > 0 }
  );

  const columns = React.useMemo<Column<LumeoFile>[]>(
    () => [
      {
        id: 'cell',
        Cell({ row }: CellProps<LumeoFile>) {
          const deployment = deploymentQueryResult.data?.data.find(
            (deployment) => deployment.id === row.original.deployment_id
          );
          const pipeline = pipelineQueryResult.data?.data.find(
            (pipeline) => pipeline.id === row.original.pipeline_id
          );
          const camera = cameraQueryResult.data?.data.find(
            (camera) => camera.id === row.original.camera_id
          );
          const stream = streamQueryResult.data?.data.find(
            (stream) => stream.id === row.original.stream_id
          );

          return (
            <DashboardFilesElement
              file={row.original}
              deployment={deployment}
              isLoadingDeployment={deploymentQueryResult.isLoading}
              pipeline={pipeline}
              isLoadingPipeline={pipelineQueryResult.isLoading}
              camera={camera}
              isLoadingCamera={cameraQueryResult.isLoading}
              stream={stream}
              isLoadingStream={streamQueryResult.isLoading}
            />
          );
        },
      },
    ],
    [
      deploymentQueryResult.data,
      deploymentQueryResult.isLoading,
      pipelineQueryResult.data,
      pipelineQueryResult.isLoading,
      cameraQueryResult.data,
      cameraQueryResult.isLoading,
      streamQueryResult.data,
      streamQueryResult.isLoading,
    ]
  );

  const [scrollHeight, setScrollHeight] = React.useState('100px');
  const scrollRefCallback = (scrollArea: HTMLDivElement) => {
    if (scrollArea) {
      setScrollHeight(`${scrollArea.clientHeight}px`);
    }
  };

  function handleEditClick() {
    editWidget(id);
  }

  function handleDeleteClick() {
    onDelete(id);
  }

  function handleDuplicateClick() {
    onDuplicate(id);
  }

  return (
    <div className={c('wrap')}>
      <div className={c('header')}>
        <Heading className={c('heading')} level={3}>
          <span>{name}</span>
        </Heading>
        <ContextMenu
          trigger={
            <IconButton
              icon="more-vertical"
              label="Reveal more actions"
              variant="ghost"
              size="small"
            />
          }
        >
          <ContextMenuAction icon="edit" onClick={handleEditClick}>
            Edit
          </ContextMenuAction>
          <ContextMenuAction icon="copy" onClick={handleDuplicateClick}>
            Duplicate
          </ContextMenuAction>
          <ContextMenuAction
            icon="delete"
            intent="danger"
            onClick={handleDeleteClick}
          >
            Delete
          </ContextMenuAction>
        </ContextMenu>
      </div>
      <div className={c('files')} ref={scrollRefCallback}>
        <ScrollArea
          style={{ height: scrollHeight }}
          className={c('scroll-area')}
        >
          <PaginatedTable
            id="dashboard-files-table"
            label="files"
            queryResult={queryResult}
            columns={columns}
            pageSize={limit}
            page={page}
            onPageChange={setPage}
            onRowClick={() => {}}
            emptyMessage={
              <EmptyView>
                No files matching the filter criteria found.
              </EmptyView>
            }
          />
        </ScrollArea>
      </div>
    </div>
  );
}

function isNotEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
