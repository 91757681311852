import React from 'react';
import classNames from 'classnames/bind';
import { DropOptions, NodeModel, Tree } from '@minoru/react-dnd-treeview';

import Tag from 'types/tag';

import { useHasAccess } from 'hooks/useHasAccess';

import { Text } from 'components/Text/Text';

import { useTagsTreeItems } from './useItems';
import { useTagsTreeSelectionState } from './useTagsTreeSelectionState';
import { TagsTreeItem } from './Item';
import { TagsTreeSearch } from './Search';
import styles from './TagsTree.module.scss';

const c = classNames.bind(styles);

export type TagsTreeProps = {
  id: string;
  defaultSelection?: Tag['id'][];
  onAdd?: (tag: Tag) => void;
  onRemove?: (tagId: Tag['id']) => void;
  onSelectionChange?: (tagIds: Tag['id'][]) => void;
};

/**
 * Component to navigate between tags and edit their hierarchy.
 * User-facing term is `label`.
 */
function TagsTree({
  id,
  defaultSelection,
  onAdd,
  onRemove,
  onSelectionChange,
}: TagsTreeProps) {
  const [hasAccess] = useHasAccess();

  const { tree, searchTree, onLoad, onDrop, setSearchResults } =
    useTagsTreeItems();

  const [selection, dispatch] = useTagsTreeSelectionState(defaultSelection);

  const canSelect = Boolean(onAdd || onSelectionChange);

  React.useEffect(() => {
    onSelectionChange?.([...selection]);
  }, [selection, onSelectionChange]);

  // Disable drag & drop for selectable tree nodes and all + untagged
  function canDrag(node: NodeModel<Tag> | undefined): boolean {
    if (!hasAccess('deploy')) {
      return false;
    }

    if (canSelect || node?.id === 'all' || node?.id === 'none') {
      return false;
    }

    return node?.droppable ?? false;
  }

  // Disable drag & drop for selectable tree nodes and all + untagged
  function canDrop(_tree: NodeModel<Tag>[], options: DropOptions<Tag>) {
    if (!hasAccess('deploy')) {
      return false;
    }

    const { dropTargetId, dragSourceId } = options;

    return (
      !canSelect &&
      dropTargetId !== 'all' &&
      dropTargetId !== 'none' &&
      dragSourceId !== dropTargetId
    );
  }

  function handleItemSelectionChange(tag: Tag, checked: boolean) {
    if (checked) {
      dispatch({ action: 'add', data: tag.id });
      onAdd?.(tag);
    } else {
      dispatch({ action: 'delete', data: tag.id });
      onRemove?.(tag.id);
    }
  }

  const hasSelectedChildren = React.useCallback(
    (tag?: Tag) => {
      if (!tag?.children) {
        return false;
      }

      return tag.children.some((item) => selection.has(item));
    },
    [selection]
  );

  return (
    <div id={id} className={c('wrap')}>
      <TagsTreeSearch id={`${id}_search`} onResultsChange={setSearchResults} />

      {(tree || searchTree) && (
        <Tree
          tree={searchTree ?? tree!}
          rootId={0}
          classes={{
            root: c('root'),
            dropTarget: c('drop-target'),
          }}
          render={(item, props) => (
            <TagsTreeItem
              {...props}
              treeId={id}
              node={item}
              tag={item.data}
              isSelected={selection.has(String(item.id))}
              isIndeterminate={hasSelectedChildren(item.data)}
              onLoad={onLoad}
              onSelectionChange={
                canSelect ? handleItemSelectionChange : undefined
              }
            />
          )}
          canDrag={canDrag}
          canDrop={canDrop}
          dragPreviewRender={(monitorProps) => (
            <span>{monitorProps.item.text}</span>
          )}
          // Sort manually to keep `all` and `none` in place
          sort={false}
          onDrop={onDrop}
          initialOpen={Boolean(searchTree)}
        />
      )}

      {searchTree && searchTree.length === 0 && (
        <Text align="center">No matching labels found.</Text>
      )}
    </div>
  );
}

const Memoized = React.memo(TagsTree);

export { Memoized as TagsTree };
