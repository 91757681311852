import React from 'react';
import { UseMutateFunction } from '@tanstack/react-query';

import Deployment from 'types/deployment';
import Pipeline from 'types/pipeline';
import { DeploymentConfiguration } from 'deployments/types/DeploymentForm';

import { sanitizeObject } from 'services/object';
import { useAllDeployments } from 'deployments/hooks/useAllDeployments';

import { ActivePipelineContext } from 'pipelines/context/active_pipeline';

import { FormMessage } from 'components/FormMessage/FormMessage';
import { Text } from 'components/Text/Text';

import { DeploymentBulkOverrideFormStep2 } from './Step2';
import { DeploymentBulkOverrideFormStepper } from './Stepper';
import { DeploymentFormProvider } from '../context/deployment_form_provider';
import { DeploymentsFormSelect } from '../DeploymentsSelect/DeploymentsSelect';
import { mergeConfigurations } from './merge_configurations';
import APIError from 'types/api_error';

type BulkOverrideDeploymentsFormContentProps = {
  pipeline: Pipeline;
  deploymentIds?: Record<Deployment['id'], boolean>;
  mutate: UseMutateFunction<Deployment[], APIError, Deployment[]>;
  error: APIError | null;
  onFormStepChange: (page: number) => void;
  onSelect: (deploymentIds: Record<Deployment['id'], boolean>) => void;
};

function BulkOverrideDeploymentsFormContent(
  {
    pipeline,
    deploymentIds: initialDeploymentIds = {},
    mutate,
    error,
    onFormStepChange,
    onSelect,
  }: BulkOverrideDeploymentsFormContentProps,
  formRef: React.ForwardedRef<HTMLFormElement>
) {
  const [formStep, setFormStep] = React.useState(1);

  const [selection, setSelection] = React.useState(initialDeploymentIds);
  const selectedDeploymentsCount = Object.keys(selection).length;

  const {
    data: deployments,
    isLoading,
    isError,
  } = useAllDeployments({
    params: {
      deployment_ids:
        selectedDeploymentsCount > 0 ? Object.keys(selection) : undefined,
      pipeline_ids: [pipeline.id],
    },
    enabled: formStep > 1,
  });

  async function redeployAll(
    overrideConfiguration: DeploymentConfiguration = {}
  ) {
    if (!deployments) {
      return;
    }

    const updatedDeployments = deployments.map(
      ({
        id,
        application_id,
        pipeline_id,
        gateway_id,
        name,
        configuration,
      }) => {
        return new Deployment(
          id,
          application_id,
          pipeline_id,
          gateway_id,
          name,
          // Null values in override reset deployment configuration
          sanitizeObject(
            mergeConfigurations(configuration, overrideConfiguration)
          )
        );
      }
    );

    mutate(updatedDeployments);
  }

  const handleStep1Continue = React.useCallback(
    (selectedDeployments: Record<Deployment['id'], boolean>) => {
      setSelection(selectedDeployments);
      setFormStep(2);
      onFormStepChange(2);
      onSelect(selectedDeployments);
    },
    [onFormStepChange, onSelect]
  );

  return (
    <ActivePipelineContext.Provider value={pipeline}>
      <DeploymentBulkOverrideFormStepper
        currentStep={formStep}
        count={Object.keys(selection).length}
        onNavigate={(step) => {
          setFormStep(step);
          onFormStepChange(step);
        }}
      />

      {error && (
        <FormMessage icon="error" intent="danger">
          <Text type="paragraph">
            <strong>{error.message}</strong>
          </Text>
          {error.code === 'invalid-pipeline-definition' && (
            <Text type="paragraph">
              There may be new required properties in the pipeline that cannot
              be bypassed in the bulk override. Please update the deployments
              individually.
            </Text>
          )}
        </FormMessage>
      )}

      {formStep === 1 && (
        <DeploymentsFormSelect
          defaultValue={selection}
          pipeline={pipeline}
          onConfirmSelection={handleStep1Continue}
        />
      )}

      {formStep === 2 && (
        <DeploymentFormProvider pipeline={pipeline}>
          <DeploymentBulkOverrideFormStep2
            onSubmit={redeployAll}
            isLoading={isLoading}
            isError={isError}
            ref={formRef}
          />
        </DeploymentFormProvider>
      )}
    </ActivePipelineContext.Provider>
  );
}

const forwardedRef = React.forwardRef(BulkOverrideDeploymentsFormContent);

export { forwardedRef as BulkOverrideDeploymentsFormContent };
