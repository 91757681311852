import React from 'react';
import classNames from 'classnames/bind';
import { CellProps } from 'react-table';

import Tag from 'types/tag';

import { TaggableEntityObject } from 'hooks/useEntities';
import {
  useAddEntityTags,
  UseAddEntityTagsOptions,
} from 'tags/hooks/useAddEntityTag';
import { useDeleteEntityTags } from 'tags/hooks/useDeleteEntityTags';

import { TagSelect } from 'tags/components/TagSelect/TagSelect';

import styles from './LabelsCell.module.scss';

const c = classNames.bind(styles);

export type TableLabelsCellProps<T extends TaggableEntityObject> =
  CellProps<T> &
    UseAddEntityTagsOptions & {
      readOnly?: boolean;
    };

export function TableLabelsCell<T extends TaggableEntityObject>({
  row: { id, original },
  entity,
  singleQueryKey,
  listQueryKey,
  readOnly,
}: TableLabelsCellProps<T>) {
  const { mutate: addTags } = useAddEntityTags<T>({
    entity,
    singleQueryKey,
    listQueryKey,
  });
  const { mutate: removeTags } = useDeleteEntityTags<T>({
    entity,
    singleQueryKey,
    listQueryKey,
  });

  const handleAdd = React.useCallback(
    (tag: Tag) => {
      addTags({ id, tagIds: [tag.id] });
    },
    [id, addTags]
  );

  const handleRemove = React.useCallback(
    (tagId: Tag['id']) => {
      removeTags({ id, tagIds: [tagId] });
    },
    [id, removeTags]
  );

  return (
    <TagSelect
      id={id}
      className={c('labels')}
      defaultSelection={original.tags}
      onAdd={handleAdd}
      onRemove={handleRemove}
      readOnly={readOnly}
    />
  );
}
