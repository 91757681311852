import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import * as SentryIntegrations from '@sentry/browser';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { Userpilot } from 'userpilot';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';

import { DndProvider } from 'react-dnd';
import { getBackendOptions, MultiBackend } from '@minoru/react-dnd-treeview';

import { APIProvider } from 'hooks/api/useAPI';
import { AuthenticationProvider } from 'hooks/api/useAuthentication';
import { IntercomProvider } from 'context/intercom';

import { RootContainer } from 'views/Root/RootContainer';

import { CookieNotice } from 'components/CookieNotice/CookieNotice';

import { LUMEO_THEME } from './mantine';
import 'index.scss';

const queryClient = new QueryClient();
const DND_OPTIONS = getBackendOptions();

if (process.env.REACT_APP_USERPILOT_ENABLED === 'true') {
  Userpilot.initialize('NX-11d5cdcc');
}

if (process.env.REACT_APP_SENTRY_DSN !== undefined) {
  const env = process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'development';

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'development',
    integrations: [SentryIntegrations.browserTracingIntegration()],
    release: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,
    allowUrls: [
      process.env.REACT_APP_BASE_URL!,
      /https?:\/\/((widget|js)\.)?intercom\.(io|com)/,
    ],
    tracesSampleRate: env !== 'development' ? 1.0 : undefined,
  });
}

(window as any).lumeo = (window as any).lumeo || {};

const root = createRoot(document.getElementById('root')!);

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={LUMEO_THEME}>
          <DndProvider backend={MultiBackend} options={DND_OPTIONS}>
            <AuthenticationProvider>
              <APIProvider>
                <TooltipProvider>
                  <RootContainer />
                  <CookieNotice />
                </TooltipProvider>
                <ReactQueryDevtools />
              </APIProvider>
            </AuthenticationProvider>
          </DndProvider>
        </MantineProvider>
      </QueryClientProvider>
    ),
  },
]);

root.render(
  <React.StrictMode>
    <IntercomProvider>
      <RouterProvider router={router} />
    </IntercomProvider>
  </React.StrictMode>
);
