import React from 'react';
import classNames from 'classnames/bind';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import Tag from 'types/tag';
import { PaginatedAPIResponse } from 'types/api';

import { useTags } from 'tags/hooks/useTags';
import { useTagsAncestors } from 'tags/hooks/useTagsAncestors';

import { Input } from 'components/Input/Input';
import { Text } from 'components/Text/Text';

import styles from './TagsTree.module.scss';

const c = classNames.bind(styles);

export type TagsTreeSearchProps = {
  id: string;
  onResultsChange: (data?: Tag[]) => void;
};

function TagsTreeSearch({ id, onResultsChange }: TagsTreeSearchProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const timeoutRef = React.useRef<number>();

  const [value, setValue] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');

  const {
    data,
    isInitialLoading: isLoadingResults,
    isSuccess,
    error,
  } = useTags(
    ['tags-suggestions', id],
    {
      tag_names: [searchTerm],
      limit: 100,
    },
    {
      keepPreviousData: true,
      enabled: Boolean(searchTerm?.trim()),
    }
  );

  const {
    data: ancestors,
    isLoading: isLoadingAncestors,
    error: ancestorsError,
  } = useTagsAncestors(data, {
    enabled: isSuccess,
    staleTime: Infinity,
  });

  React.useEffect(() => {
    if (
      !searchTerm.trim() ||
      !data ||
      ancestors.some((x) => !x) ||
      isLoadingAncestors
    ) {
      return;
    }

    const combined = [
      ...data.data,
      ...(ancestors as PaginatedAPIResponse<Tag>[]).flatMap(({ data }) => data),
    ];

    const uniqueTags = [
      ...new Map(combined.map((tag) => [tag.id, tag])).values(),
    ];

    onResultsChange(uniqueTags);
  }, [searchTerm, data, ancestors, isLoadingAncestors, onResultsChange]);

  React.useEffect(() => {
    if (!searchTerm.trim()) {
      onResultsChange(undefined);
    }
  }, [searchTerm, onResultsChange]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    window.clearTimeout(timeoutRef.current);
    setValue(event.target.value);

    timeoutRef.current = window.setTimeout(() => {
      setSearchTerm(event.target.value.trim());
    }, 250);
  }

  return (
    <label htmlFor={id} className={c('search')}>
      <VisuallyHidden>Search labels</VisuallyHidden>
      <div>
        <Input
          id={id}
          className={c('search-input')}
          icon="search"
          type="search"
          placeholder="Search..."
          size="small"
          loading={isLoadingResults || isLoadingAncestors}
          value={value}
          onChange={handleChange}
          ref={inputRef}
        />
      </div>

      {(error || ancestorsError) && (
        <Text intent="danger" size="xsmall">
          {error?.message ?? ancestorsError?.error?.message}
        </Text>
      )}
    </label>
  );
}

const Memoized = React.memo(TagsTreeSearch);

export { Memoized as TagsTreeSearch };
