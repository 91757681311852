import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Tag from 'types/tag';
import { OffsetPaginationParams } from 'types/pagination';
import { PaginatedAPIResponse } from 'types/api';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export type UseTagsFilterParams = OffsetPaginationParams & {
  tag_ids?: Tag['id'][];
  tag_names?: Tag['name'][];

  /** Filter by parents */
  parents?: Tag['id'][];

  /** Filter by parents or grandparents */
  ancestors?: Tag['id'][];

  /** Filter for only top-level tags */
  only_roots?: boolean;
};

export function useTags(
  queryKey: QueryKey = ['tags'],
  {
    pagination = 'offset',
    page = 1,
    limit = 50,
    ...filters
  }: UseTagsFilterParams = {},
  options: UseQueryOptions<PaginatedAPIResponse<Tag>, APIError> = {}
) {
  const api = useAPI();
  const params = {
    pagination,
    page,
    limit,
    ...filters,
  };

  return useAuthenticatedQuery(
    [...queryKey, params],
    () => api.tags.list(params),
    options
  );
}
