import React from 'react';
import { useQueries, UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Tag from 'types/tag';
import { PaginatedAPIResponse } from 'types/api';

import { useAPI } from 'hooks/api/useAPI';

import { UseTagsFilterParams } from './useTags';

const TAGS_ANCESTORS_MAX_LIMIT = 1;

export function useTagsAncestors(
  data: PaginatedAPIResponse<Tag> | undefined,
  options?: UseQueryOptions<PaginatedAPIResponse<Tag>, APIError>
) {
  const api = useAPI();

  const ancestorIDs = React.useMemo(() => {
    if (!data) {
      return [];
    }

    const uniqueIDs = [...new Set(data.data.flatMap(({ path }) => path))];
    const chunks = Math.ceil(uniqueIDs.length / TAGS_ANCESTORS_MAX_LIMIT);
    const chunkSize = Math.ceil(uniqueIDs.length / chunks);

    const ids = [];

    while (uniqueIDs.length) {
      ids.push(uniqueIDs.splice(0, chunkSize));
    }

    return ids;
  }, [data]);

  const queries = useQueries({
    queries: ancestorIDs.map((ids) => {
      const params: UseTagsFilterParams = {
        pagination: 'offset',
        page: 1,
        limit: ids.length,
        tag_ids: ids,
      };

      return {
        queryKey: ['tags', api.applicationID, params],
        queryFn() {
          return api.tags.list(params);
        },
        ...options,
      };
    }),
  });

  return React.useMemo(
    () => ({
      data: queries.map(({ data }) => data),
      isLoading: queries.some(({ isLoading }) => isLoading),
      isSuccess: queries.every(({ isSuccess }) => isSuccess),
      isError: queries.some(({ isError }) => isError),
      error: queries.find(({ error }) => error),
    }),
    [queries]
  );
}
