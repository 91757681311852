import {
  createModelSchema,
  deserialize,
  identifier,
  list,
  primitive,
  serialize,
} from 'serializr';

export default class Tag {
  id: string;

  application_id: string;

  name: string;

  parent: Tag['id'] | null;

  /**
   * Path of the tag IDs in the tag hierarchy that this tag is under,
   * starting at the root. Not including this tag itself.
   */
  path: Tag['id'][];

  /** List of the tag IDs of all tags that have this tag as parent. */
  children: Tag['id'][];

  /** ISO date string */
  created_at: string;

  /** ISO date string */
  updated_at: string;

  constructor(
    id: string,
    name: string,
    applicationID: string,
    parent?: Tag['id'],
    path?: Tag['id'][],
    children?: Tag['id'][]
  ) {
    this.id = id;
    this.name = name;
    this.application_id = applicationID;
    this.parent = parent ?? null;
    this.path = path ?? [];
    this.children = children ?? [];

    // usually the dates come from the deserialized API response,
    // so these values are just a fallback for locally created tags
    this.created_at = new Date().toISOString();
    this.updated_at = new Date().toISOString();
  }

  copy(): Tag {
    return deserialize(Tag, serialize(this)) as unknown as Tag;
  }
}

createModelSchema(Tag, {
  id: identifier(),
  name: primitive(),
  application_id: primitive(),
  parent: identifier(),
  path: list(identifier()),
  children: list(identifier()),
  created_at: primitive(),
  updated_at: primitive(),
});
