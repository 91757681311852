import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';

import APIError from 'types/api_error';
import { FileCloudStatus, LumeoFile } from 'types/file';

export type UpdateFileVariables = {
  fileId: string;
  name?: string;
  cloud_status?: FileCloudStatus;
  description?: string;
  optimisticUpdateData?: LumeoFile;
};

export function useUpdateFile({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<LumeoFile, APIError, UpdateFileVariables> = {}) {
  const queryClient = useQueryClient();
  const { applicationID, files } = useAPI();

  return useMutation((variables) => files.update(variables), {
    ...options,
    onMutate({ fileId, optimisticUpdateData, ...rest }) {
      onMutate?.({ fileId, optimisticUpdateData, ...rest });

      if (optimisticUpdateData) {
        queryClient.cancelQueries(['file', fileId, applicationID]);

        return updateQueriesData({
          queryClient,
          singleQueryKey: ['file'],
          ids: [fileId],
          updateData: optimisticUpdateData,
        });
      }
    },
    onError(error, file, context) {
      onError?.(error, file, context);

      context?.previousSingles?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
    },
    onSettled(data, error, variables, context) {
      onSettled?.(data, error, variables, context);

      const { fileId } = variables;

      queryClient.invalidateQueries(['file', fileId, applicationID]);
    },
  });
}
