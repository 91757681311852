import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { LightboxContext } from 'files/context/lightbox';
import { addDeleteConfirmationDialog } from 'services/dialog';
import { browserSupportsVideoType } from 'services/browser_supports_video_type';
import { useDeleteFiles } from 'files/hooks/useDeleteFiles';
import { useFile } from 'files/hooks/useFile';

import downloadFile from 'services/download_file';

import { Content, ContentProps } from './Content';

export type ProviderProps = React.PropsWithChildren<
  Pick<ContentProps, 'onOpenChange' | 'defaultOpen'> & {}
>;

/** @deprecated */
export function Provider({
  children,
  defaultOpen,
  onOpenChange,
}: ProviderProps) {
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  const [fileId, setFileId] = React.useState<string>();
  const { data: file, isLoading } = useFile(fileId);

  const mimeType = file?.mime_type;
  const canDisplayMedia = mimeType?.includes('video')
    ? browserSupportsVideoType(mimeType)
    : Boolean(mimeType);

  const { mutate: deleteFiles, isLoading: isDeleting } = useDeleteFiles({
    onSuccess() {
      setIsOpen(false);
      queryClient.invalidateQueries(['files-search']);
    },
  });

  const viewMedia = React.useCallback((fileId: string) => {
    setIsOpen(true);
    setFileId(fileId);
  }, []);

  const downloadMedia = React.useCallback(() => {
    if (!file || !file.data_url) {
      return;
    }

    downloadFile(file.name, file.data_url);
  }, [file]);

  const deleteFile = React.useCallback(() => {
    if (!fileId) {
      return;
    }

    addDeleteConfirmationDialog({
      title: 'Are you sure you want to delete this file?',
      onConfirm: () => deleteFiles({ file_ids: [fileId] }),
      isLoading: isDeleting,
    });
  }, [deleteFiles, fileId, isDeleting]);

  function handleOpenChange(isOpen: boolean) {
    setIsOpen(isOpen);
    onOpenChange?.(isOpen);
  }

  return (
    <LightboxContext.Provider
      value={{
        file,
        url: file?.data_url,
        mimetype: mimeType,
        canDisplayMedia,
        isLoading,
        isDeleting,
        viewMedia,
        download: downloadMedia,
        deleteFile,
      }}
    >
      {children}

      <Content
        open={isOpen}
        defaultOpen={defaultOpen}
        onOpenChange={handleOpenChange}
      />
    </LightboxContext.Provider>
  );
}
