import React from 'react';
import { MutationFunction, UseMutationOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';

import { addDeleteConfirmationDialog } from 'services/dialog';
import { useMutationWithError } from './useMutationWithError';

export type UseDangerousMutationOptions<TData, TContext, TVariables> =
  UseMutationOptions<TData, APIError, TVariables, TContext> & {
    info?: string;
    entity?: string;
    action?: string;
    onCancel?: () => void;
  };

/**
 * Extension of react-query `useMutation` that adds an error notification.
 *
 * @deprecated Call {@link addDeleteConfirmationDialog} directly instead.
 */
export function useDangerousMutation<
  TData = undefined,
  TContext = unknown,
  TVariables = void,
>(
  message: string,
  mutationFn: MutationFunction<TData, TVariables>,
  {
    info,
    onCancel,
    ...options
  }: UseDangerousMutationOptions<TData, TContext, TVariables>
) {
  const mutation = useMutationWithError<TData, TVariables, TContext>(
    mutationFn,
    options
  );

  const { isLoading, mutateAsync } = mutation;

  const requestDangerousMutation = React.useCallback(
    (variables: TVariables) => {
      addDeleteConfirmationDialog({
        title: message,
        message: info,
        isLoading,
        onConfirm: () => mutateAsync(variables),
        onCancel,
      });
    },
    [mutateAsync, onCancel, info, message, isLoading]
  );

  return [requestDangerousMutation, mutation] as const;
}
