import { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Deployment from 'types/deployment';

import { addErrorNotification } from 'services/notification';
import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { useBillingMutation } from 'hooks/api/useBillingMutation';
import { useMutationWithError } from 'hooks/api/useMutationWithError';

export function useDeployment(
  deploymentID?: string,
  options?: Omit<
    UseQueryOptions<Deployment | undefined, APIError>,
    'placeholderData'
  >
) {
  const api = useAPI();

  return useAuthenticatedQuery<Deployment | undefined>(
    ['deployment', deploymentID],

    () => {
      if (!deploymentID) {
        return Promise.reject(new Error('No pipeline ID provided.'));
      }

      return api.deployments.read(deploymentID);
    },
    {
      ...options,
      keepPreviousData: false,
    }
  );
}

type ToggleState = 'stopping' | 'deploying';
type ToggleOptions = UseMutationOptions<Deployment, APIError, Deployment, any>;

export function useToggleDeploymentState(options: ToggleOptions = {}) {
  const api = useAPI();

  const { mutate: start, isLoading: isStarting } = useBillingMutation(
    (deployment: Deployment) => api.deployments.start(deployment.id),
    {
      ...options,
      entity: 'deployment',
      action: 'start',
      onError(error) {
        addErrorNotification({ title: 'Failed to start deployment.', error });
      },
    }
  );
  const { mutate: stop, isLoading: isStopping } = useMutationWithError(
    (deployment: Deployment) => api.deployments.stop(deployment.id),
    {
      ...options,
      entity: 'deployment',
      action: 'stop',
      onError(error) {
        addErrorNotification({ title: 'Failed to stop deployment.', error });
      },
    }
  );

  function toggleState(deployment: Deployment): ToggleState {
    if (deployment.isActive) {
      stop(deployment);
      return 'stopping';
    }

    start(deployment);
    return 'deploying';
  }

  return {
    toggleState,
    isLoading: isStarting || isStopping,
  };
}
