import React from 'react';
import classNames from 'classnames/bind';
import { useForm } from 'react-hook-form';

import { LumeoFile } from 'types/file';

import { useSpringState } from 'hooks/useSpringState';
import { useUpdateFile } from 'files/hooks/useUpdateFile';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Field } from 'components/Field/Field';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Textarea } from 'components/Textarea/Textarea';

import styles from './FileDetail.module.scss';

const c = classNames.bind(styles);

type FileFieldValues = { description: string };

export type FileDetailDescriptionProps = {
  file?: LumeoFile;
};

export function FileDetailDescription({ file }: FileDetailDescriptionProps) {
  const [isSuccess, setIsSuccess] = useSpringState(false, 2000);

  const description = file?.description;

  const {
    formState: { isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<FileFieldValues>({
    defaultValues: { description },
  });

  const { mutate: mutateFile, isLoading: isUpdating } = useUpdateFile({
    onSuccess(updatedFile) {
      setIsSuccess(true);
      reset({ description: updatedFile.description });
    },
  });

  function onSubmit({ description }: FileFieldValues) {
    if (!file) {
      return;
    }

    const updatedFile = { ...file, description };

    mutateFile({
      fileId: updatedFile.id,
      description,
      optimisticUpdateData: updatedFile,
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={c('description-form')}>
      <Field id="file-description" label="Description">
        <Textarea id="file-description" {...register('description')} />
      </Field>

      <ButtonGroup>
        <Button
          variant="primary"
          type="submit"
          loading={isUpdating}
          disabled={!isDirty}
        >
          Save description
        </Button>

        {isDirty && <InlineNotification>Unsaved changes</InlineNotification>}

        {isSuccess && (
          <InlineNotification intent="success">Saved!</InlineNotification>
        )}
      </ButtonGroup>
    </form>
  );
}
