import React from 'react';
import classNames from 'classnames/bind';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import { UseQueryResult } from '@tanstack/react-query';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import APIError from 'types/api_error';
import { PaginatedAPIResponse } from 'types/api';

import { Icon } from 'components/Icon/Icon';

import { QuerySearchProps, SearchableEntity } from './QuerySearch';
import { QuerySearchSuggestions } from './Suggestions';
import styles from './QuerySearchInput.module.scss';

const c = classNames.bind(styles);

type QuerySearchInputProps<T extends SearchableEntity> = Omit<
  QuerySearchProps<T>,
  'entityName' | 'totalElements' | 'filters'
>;

export function QuerySearchInput<T extends SearchableEntity>({
  value,
  searchLabel = 'Search...',
  searchResultField,
  searchQueryResult,
  onApplyFilters,
  onSuggestionSelect,
  onValueChange,
}: QuerySearchInputProps<T>) {
  if (searchQueryResult && searchResultField) {
    return (
      <QuerySearchInputWithSuggestions
        value={value}
        searchQueryResult={searchQueryResult}
        searchResultField={searchResultField}
        searchLabel={searchLabel}
        onApplyFilters={onApplyFilters}
        onValueChange={onValueChange}
        onSuggestionSelect={onSuggestionSelect}
      />
    );
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      onApplyFilters?.();
    }
  }

  return (
    <div className={c('combobox')}>
      <label className={c('input-wrap')}>
        <Icon name="search" />
        <VisuallyHidden>{searchLabel}</VisuallyHidden>
        <input
          className={c('input')}
          value={value}
          placeholder={searchLabel}
          onChange={(event) => onValueChange(event.target.value)}
          onKeyDown={handleKeyDown}
          type="search"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
        />
      </label>
    </div>
  );
}

type QuerySearchInputWithSuggestionsProps<T extends SearchableEntity> = Omit<
  QuerySearchProps<T>,
  | 'entityName'
  | 'totalElements'
  | 'filters'
  | 'searchQueryResult'
  | 'searchResultField'
> & {
  searchResultField: keyof T;
  searchQueryResult: UseQueryResult<PaginatedAPIResponse<T>, APIError>;
};

function QuerySearchInputWithSuggestions<T extends SearchableEntity>({
  value,
  searchLabel = 'Search...',
  searchResultField,
  searchQueryResult,
  onApplyFilters,
  onSuggestionSelect,
  onValueChange,
}: QuerySearchInputWithSuggestionsProps<T>) {
  const items = searchQueryResult.data?.data || [];

  function handleValueChange({ inputValue }: UseComboboxStateChange<T | null>) {
    onValueChange(inputValue);
  }

  const {
    isOpen,
    highlightedIndex,
    inputValue,
    getLabelProps,
    getComboboxProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    closeMenu,
  } = useCombobox<T | null>({
    // null is appended to keep the "ALl results for..." option accessible
    items: [...items, null],
    itemToString,
    onSelectedItemChange: handleItemSelect,
    onInputValueChange: handleValueChange,
  });

  function itemToString(item: T | null) {
    return item &&
      searchResultField in item &&
      typeof item[searchResultField] === 'string'
      ? String(item[searchResultField])
      : inputValue;
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && highlightedIndex === -1) {
      onApplyFilters?.();
      closeMenu();
    }
  }

  function handleItemSelect({
    selectedItem,
  }: UseComboboxStateChange<T | null>) {
    if (!selectedItem) {
      return;
    }

    if (onSuggestionSelect) {
      onSuggestionSelect(selectedItem);
      return;
    }

    onApplyFilters?.();
    closeMenu();
  }

  return (
    <div {...getComboboxProps()} className={c('combobox')}>
      <label {...getLabelProps()} className={c('input-wrap')}>
        <Icon name="search" />
        <VisuallyHidden>{searchLabel}</VisuallyHidden>
        <input
          {...getInputProps({ onKeyDown: handleKeyDown, value })}
          className={c('input')}
          placeholder={searchLabel}
          type="search"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
        />
      </label>

      <div
        {...getMenuProps()}
        className={c('suggestions', { open: isOpen && inputValue.trim() })}
      >
        {isOpen && (
          <QuerySearchSuggestions
            items={items}
            inputValue={inputValue}
            getItemProps={getItemProps}
            itemToString={itemToString}
            highlightedIndex={highlightedIndex}
            searchQueryResult={searchQueryResult}
            onApplyFilters={onApplyFilters}
            closeMenu={closeMenu}
          />
        )}
      </div>
    </div>
  );
}
