import React from 'react';
import * as RadixPopover from '@radix-ui/react-popover';
import classNames from 'classnames/bind';

import Tag from 'types/tag';

import { useTags } from 'tags/hooks/useTags';

import { Pill } from 'components/Pill/Pill';

import { TagsTree, TagsTreeProps } from '../TagsTree/TagsTree';
import styles from './TagSelect.module.scss';

const c = classNames.bind(styles);

export type TagSelectProps = Pick<TagsTreeProps, 'id'> & {
  className?: string;
  defaultSelection?: Tag['id'][];
  trigger?: JSX.Element;
  readOnly?: boolean;
  onSelectionChange?: (selection: Tag[]) => void;
  onAdd?: (tag: Tag) => void;
  onRemove?: (tagId: Tag['id']) => void;
};

type RenderListProps = {
  defaultSelection?: Tag['id'][];
  selection: Record<Tag['id'], Tag>;
  isLoading: boolean;
};

function renderList({
  defaultSelection,
  selection,
  isLoading,
}: RenderListProps) {
  const activeSelection = Object.values(selection);

  if (!isLoading) {
    return activeSelection.map(({ id, name }) => (
      <Pill key={id} intent="info">
        {name}
      </Pill>
    ));
  }

  return defaultSelection?.map((id) => {
    if (selection[id]) {
      const { name } = selection[id];
      return (
        <Pill key={id} intent="info">
          {name}
        </Pill>
      );
    }

    return <span className={c('skeleton-bar', 'list-skeleton')} key={id} />;
  });
}

export function TagSelect({
  className,
  defaultSelection,
  trigger,
  readOnly,
  onSelectionChange,
  onAdd,
  onRemove,
  ...treeProps
}: TagSelectProps) {
  const [isInitialized, setIsInitialized] = React.useState(false);

  const [selection, setSelection] = React.useState<Record<Tag['id'], Tag>>({});

  const { id } = treeProps;
  const { data, isLoading } = useTags(
    ['tags', id, defaultSelection],
    {
      tag_ids: defaultSelection,
      limit: defaultSelection?.length,
    },
    {
      enabled: defaultSelection && defaultSelection.length > 0,
    }
  );

  React.useEffect(() => {
    if (defaultSelection && !defaultSelection.length) {
      setSelection({});
      setIsInitialized(true);
      return;
    }

    if (!data) {
      return;
    }

    setSelection(
      data.data.reduce<Record<Tag['id'], Tag>>((selection, tag) => {
        selection[tag.id] = selection[tag.id] ?? tag;
        return selection;
      }, {})
    );
    setIsInitialized(true);
  }, [data, defaultSelection]);

  React.useEffect(() => {
    if (!isInitialized) {
      return;
    }

    onSelectionChange?.(Object.values(selection));
  }, [isInitialized, selection, onSelectionChange]);

  function handleAdd(tag: Tag) {
    if (readOnly) {
      return;
    }

    setSelection((selection) => ({ ...selection, [tag.id]: tag }));
    onAdd?.(tag);
  }

  function handleRemove(tagId: Tag['id']) {
    if (readOnly) {
      return;
    }

    setSelection(({ [tagId]: removedTag, ...selection }) => selection);
    onRemove?.(tagId);
  }

  const list = renderList({
    defaultSelection,
    selection,
    isLoading,
  });

  return (
    <RadixPopover.Root open={readOnly ? false : undefined}>
      <RadixPopover.Anchor>
        {readOnly && (
          <div className={c('list', 'readonly', className)}>{list}</div>
        )}

        {!readOnly &&
          (trigger ? (
            <div className={c(className, 'readonly')}>
              <RadixPopover.Trigger
                onClick={(event) => event.stopPropagation()}
                asChild
              >
                <div className={c('list')}>{list}</div>
              </RadixPopover.Trigger>
              <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
            </div>
          ) : (
            <RadixPopover.Trigger
              onClick={(event) => event.stopPropagation()}
              asChild
            >
              <div className={c(className, 'readonly')}>
                <div className={c('list')}>{list}</div>
              </div>
            </RadixPopover.Trigger>
          ))}
      </RadixPopover.Anchor>

      <RadixPopover.Content
        side="bottom"
        align="start"
        className={c('content')}
      >
        <div className={c('menu')}>
          <TagsTree
            {...treeProps}
            defaultSelection={
              isLoading ? defaultSelection : Object.keys(selection)
            }
            onAdd={handleAdd}
            onRemove={handleRemove}
          />
        </div>
      </RadixPopover.Content>
    </RadixPopover.Root>
  );
}
