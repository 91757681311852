import React from 'react';
import classNames from 'classnames/bind';

import { Icon } from 'components/Icon/Icon';
import { Loader } from 'components/Loader/Loader';
import { Text } from 'components/Text/Text';

import { useLightbox } from 'files/context/lightbox';
import styles from './Lightbox.module.scss';

const c = classNames.bind(styles);

/** @deprecated */
export function Preview() {
  const { url, mimetype, canDisplayMedia, isLoading } = useLightbox();

  if (isLoading) {
    return (
      <div className={c('preview')}>
        <Loader text="Loading media..." size="small" />
      </div>
    );
  }

  if (!url) {
    return (
      <div className={c('preview')}>
        <Icon name="error" className="theme danger" size="large" />
        <Text>Unable to load media.</Text>
      </div>
    );
  }

  if (!mimetype || !canDisplayMedia) {
    return (
      <div className={c('preview')}>
        <Icon name="error" className="theme danger" size="large" />
        <Text>Unsupported file type: Cannot display media.</Text>
      </div>
    );
  }

  if (mimetype.includes('video')) {
    return (
      <div className={c('preview')}>
        <video controls muted autoPlay>
          <source src={url} type={mimetype} />
          Failed to load the video.
        </video>
      </div>
    );
  }

  if (mimetype.includes('image')) {
    return (
      <div className={c('preview')}>
        <img src={url} alt="" />
      </div>
    );
  }

  return (
    <div className={c('preview')}>
      <Icon name="error" className="theme danger" size="large" />
      <Text>Unable to view file of type {mimetype}.</Text>
    </div>
  );
}
