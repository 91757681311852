import React from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@mantine/core';
import { UseQueryResult } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';
import Stream from 'types/stream';
import { FilesSearchResult } from 'types/files_search_result';
import { LumeoFile } from 'types/file';
import { OffsetPaginated } from 'types/pagination';

import { isNotNullOrUndefined } from 'services/nullable';
import { useCameras } from 'cameras/hooks/useCameras';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useStreams } from 'streams/hooks/useStreams';

import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { Thumbnail } from 'components/Thumbnail/Thumbnail';

import { FilesSearchItem } from './Item';
import styles from './Search.module.scss';

const c = classNames.bind(styles);

type FilesSearchResultsProps = {
  queryResult: UseQueryResult<
    OffsetPaginated<FilesSearchResult | LumeoFile>,
    APIError
  >;
};

export function FilesSearchResults({ queryResult }: FilesSearchResultsProps) {
  const { isLoading: isLoadingPlan } = useCurrentPlan();
  const {
    isLoading: isLoadingFiles,
    isPreviousData,
    isFetching,
    data,
  } = queryResult;

  const streamIds: Stream['id'][] = [
    ...new Set(
      data?.data.map(({ stream_id }) => stream_id).filter(isNotNullOrUndefined)
    ),
  ];

  const { data: streams, isLoading: isLoadingStreams } = useStreams(
    ['files-search-streams'],
    {
      stream_ids: streamIds,
      limit: streamIds.length,
    },
    { enabled: streamIds.length > 0 }
  );

  const streamsCamerasMap: Record<Stream['id'], Camera['id']> =
    streams?.data.reduce(
      (acc, { id, camera_id }) => {
        if (camera_id) {
          acc[id] = camera_id;
        }
        return acc;
      },
      {} as Record<Stream['id'], Camera['id']>
    ) ?? {};
  const cameraIds = [...new Set(Object.values(streamsCamerasMap))];

  const { data: cameras, isLoading: isLoadingCameras } = useCameras(
    {
      camera_ids: cameraIds,
      limit: cameraIds.length,
    },
    { enabled: cameraIds.length > 0 }
  );

  const isLoading =
    isLoadingFiles || isLoadingStreams || isLoadingCameras || isLoadingPlan;

  if ((isLoading && !data) || (isFetching && isPreviousData)) {
    return (
      <div className={c('grid')}>
        <SkeletonList
          min={data && data.data.length > 0 ? data.data.length : 48}
          max={data && data.data.length > 0 ? data.data.length : 48}
          component={
            <div className={c('item-view-button')}>
              <Thumbnail isLoading />
              <Skeleton height="1rem" width="30%" visible />
              <Skeleton height="1.5rem" width="90%" visible />
            </div>
          }
        />
      </div>
    );
  }

  if (!data || !data.total_elements) {
    return <div className={c('info')}>No results</div>;
  }

  return (
    <div className={c('grid')}>
      {data.data.map((item) => {
        const cameraId = item.stream_id && streamsCamerasMap[item.stream_id];
        const isFromCameraStream = Boolean(item.stream_id && cameraId);

        const source = isFromCameraStream
          ? cameras?.data.find(({ id }) => id === cameraId)
          : streams?.data.find(({ id }) => id === item.stream_id);

        // FilesSearchResult
        if ('file_name' in item) {
          return (
            <FilesSearchItem
              key={item.file_id}
              id={item.file_id}
              name={item.file_name}
              thumbnail_url={item.thumbnail_url}
              mime_type={item.mime_type}
              created_at={item.created_at}
              events={item.events}
              objects={item.objects}
              source={source}
            />
          );
        }

        // LumeoFile
        return (
          <FilesSearchItem
            key={item.id}
            id={item.id}
            name={item.name}
            thumbnail_url={item.thumbnail_url}
            mime_type={item.mime_type}
            created_at={item.created_at}
            source={source}
          />
        );
      })}
    </div>
  );
}
