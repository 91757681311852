import Stream from 'types/stream';

import { addDeleteConfirmationDialog } from 'services/dialog';
import { useDeleteFiles } from 'files/hooks/useDeleteFiles';
import { useDeleteStream } from 'streams/hooks/useDeleteStream';
import { useDownloadFile } from 'files/hooks/useDownloadFile';

import {
  ContextMenu,
  ContextMenuAction,
  ContextMenuSeparator,
} from 'components/ContextMenu/ContextMenu';
import { IconButton } from 'components/IconButton/IconButton';

export type FileActionsProps = {
  file: Stream;
  onRenameClick: (fileID: Stream['id']) => void;
};

export function FileActions({ file, onRenameClick }: FileActionsProps) {
  const { mutate: deleteFiles, isLoading: isDeletingFiles } = useDeleteFiles();
  const [requestDeleteFileStream] = useDeleteStream(['streams']);
  const { download } = useDownloadFile();

  const isLinkedFile = file.uri && !file.uri.includes('lumeo://');

  function handleRenameClick() {
    onRenameClick(file.id);
  }

  function handleDownloadClick() {
    if (!file.fileID) {
      return;
    }

    download(file.fileID);
  }

  function handleCopyUrlClick() {
    if (!isLinkedFile) {
      return;
    }

    navigator.clipboard.writeText(file.uri!);
  }

  function handleDeleteClick() {
    if (file.fileID) {
      addDeleteConfirmationDialog({
        title: 'Are you sure you want to delete this file?',
        onConfirm: () => deleteFiles({ file_ids: [file.id] }),
        isLoading: isDeletingFiles,
      });
      return;
    }

    if (file.id) {
      requestDeleteFileStream(file.id);
    }
  }

  return (
    <ContextMenu
      trigger={
        <IconButton
          label="More actions"
          variant="ghost"
          icon="more-vertical"
          size="small"
        />
      }
    >
      <ContextMenuAction onClick={handleRenameClick} icon="edit">
        Rename
      </ContextMenuAction>

      {file.fileID ? (
        <ContextMenuAction onClick={handleDownloadClick} icon="download">
          Download
        </ContextMenuAction>
      ) : undefined}

      {isLinkedFile ? (
        <ContextMenuAction onClick={handleCopyUrlClick} icon="clipboard">
          Copy URL
        </ContextMenuAction>
      ) : undefined}

      <ContextMenuSeparator />

      <ContextMenuAction
        onClick={handleDeleteClick}
        intent="danger"
        icon="delete"
      >
        Delete
      </ContextMenuAction>
    </ContextMenu>
  );
}
