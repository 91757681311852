import React from 'react';

import Stream from 'types/stream';
import { LumeoFile } from 'types/file';

import WebRTCPlayer from 'components/WebRTCPlayer/WebRTCPlayer';
import { Icon } from 'components/Icon/Icon';
import { MediaOverlay } from 'components/MediaOverlay/MediaOverlay';
import { Text } from 'components/Text/Text';

export type MediaContextProps =
  | {
      playingStream?: Stream;
      setPlayingStream: (stream: Stream) => void;
      file?: LumeoFile;
      setViewingFile: (file: LumeoFile, contents: any) => void;
    }
  | undefined;

const MediaContext = React.createContext<MediaContextProps>(undefined);

function renderMedia(url: string | undefined, mimetype?: string) {
  if (!url) {
    return null;
  }

  if (mimetype?.includes('video')) {
    return (
      <video controls muted>
        <source src={url} type="video/mp4" />
        Failed to load the video.
      </video>
    );
  }

  if (mimetype?.includes('image')) {
    return <img src={url} alt="" />;
  }

  return (
    <div className="error">
      <Icon name="error" />
      <Text>Unable to view this file type.</Text>
    </div>
  );
}

/** @deprecated */
export function MediaContextProvider(props: React.PropsWithChildren<{}>) {
  const [playingStream, setPlayingStream] = React.useState<Stream>();

  const [viewingFile, setViewingFile] = React.useState<string>();
  const [file, setFile] = React.useState<LumeoFile>();

  const mediaContext = {
    playingStream,
    setPlayingStream,
    file,
    setViewingFile: (file: LumeoFile, contents?: string) => {
      setFile(file);
      setViewingFile(contents);
    },
  };

  const mediaOverlay = React.useMemo(() => {
    function closeMediaOverlay() {
      setViewingFile(undefined);
      setFile(undefined);
      setPlayingStream(undefined);
    }

    const mimeType = file?.mime_type;

    return (
      (playingStream || viewingFile) && (
        <MediaOverlay onRequestClose={closeMediaOverlay}>
          {playingStream && (
            <WebRTCPlayer streamID={String(playingStream.id)} auto />
          )}

          {viewingFile && mimeType && renderMedia(viewingFile, mimeType)}
        </MediaOverlay>
      )
    );
  }, [file?.mime_type, playingStream, viewingFile]);

  return (
    <MediaContext.Provider value={mediaContext}>
      {props.children}
      {mediaOverlay}
    </MediaContext.Provider>
  );
}

/** @deprecated */
export function useMedia() {
  const context = React.useContext(MediaContext);
  if (context === undefined) {
    throw new Error(
      '`useMedia` cannot be used outside of an `MediaContextProvider`.'
    );
  }
  return context;
}

export default MediaContext;
