import React from 'react';

import Tag from 'types/tag';

type AddAction = {
  action: 'add';
  data: Tag['id'] | Tag['id'][];
};

type DeleteAction = {
  action: 'delete';
  data: Tag['id'];
};

type SetAction = {
  action: 'set';
  data: Set<Tag['id']> | Tag['id'][];
};

type UseSelectionAction = AddAction | DeleteAction | SetAction;

export function useTagsTreeSelectionState(defaultSelection?: Tag['id'][]) {
  return React.useReducer(
    (selection: Set<Tag['id']>, { action, data }: UseSelectionAction) => {
      switch (action) {
        case 'add': {
          const updated = new Set(selection);

          if (Array.isArray(data)) {
            data.forEach(updated.add, updated);
          } else {
            updated.add(data);
          }

          return updated;
        }
        case 'delete': {
          const updated = new Set(selection);
          updated.delete(data);
          return updated;
        }
        case 'set': {
          return new Set(data);
        }
      }
    },
    defaultSelection ? new Set(defaultSelection) : new Set<Tag['id']>()
  );
}
