import React from 'react';
import classNames from 'classnames/bind';

import { LumeoFile } from 'types/file';

import downloadFile from 'services/download_file';
import { useFile } from 'files/hooks/useFile';
import { useStream } from 'streams/hooks/useStream';

import { EntityList, EntityListItem } from 'components/EntityList';
import { FileDetailDescription } from 'files/views/FileDetail/Description';
import { FileDetailPreview } from 'files/views/FileDetail/Preview';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { Tabs, TabsButton, TabsContent, TabsList } from 'components/Tabs/Tabs';

import styles from './FilesSearchDetail.module.scss';

const c = classNames.bind(styles);

type FilesSearchDetailProps = {
  file: Pick<LumeoFile, 'id' | 'name'>;
};

export function FilesSearchDetail({
  file: { id, name },
}: FilesSearchDetailProps) {
  const { data: file, isLoading: isLoadingFile } = useFile(id);
  const { data: stream, isInitialLoading: isLoadingStream } = useStream(
    file?.stream_id
  );

  return (
    <div className={c('file-detail')}>
      <div className={c('header')}>
        <Heading level="1" className={c('heading')}>
          {name}
        </Heading>

        {stream && (
          <EntityList className={c('entities')}>
            <EntityListItem
              icon="stream"
              label="Stream"
              type="streams"
              loading={isLoadingStream}
              entity={stream}
            />
          </EntityList>
        )}

        <IconButton
          className={c('button')}
          icon="download"
          label="Download"
          size="small"
          variant="secondary"
          loading={isLoadingFile}
          onClick={() => file?.data_url && downloadFile(name, file.data_url)}
          disabled={!file?.data_url}
          showLabel
        />
      </div>
      <Tabs defaultValue="preview">
        <TabsList>
          <TabsButton value="preview">Preview</TabsButton>
        </TabsList>

        <TabsContent value="preview" className={c('preview')}>
          <FileDetailPreview
            url={file?.data_url}
            mimeType={file?.mime_type}
            cloudStatus={file?.cloud_status}
            isLoading={isLoadingFile}
          />
          {file && <FileDetailDescription file={file} />}
        </TabsContent>
      </Tabs>
    </div>
  );
}
