import React from 'react';
import classNames from 'classnames/bind';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Tag from 'types/tag';
import { ApplicationParams } from 'application/types/application_params';

import { addDeleteConfirmationDialog } from 'services/dialog';
import { useAddEntityTags } from 'tags/hooks/useAddEntityTag';
import { useDeleteEntityTags } from 'tags/hooks/useDeleteEntityTags';
import { useDeleteFiles } from 'files/hooks/useDeleteFiles';
import { useDownloadFile } from 'files/hooks/useDownloadFile';
import { useMedia } from 'services/media_context';
import { useSpringState } from 'hooks/useSpringState';
import { useUpdateFile } from 'files/hooks/useUpdateFile';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Loader } from 'components/Loader/Loader';
import { Separator } from 'components/Separator/Separator';
import { TagSelect } from 'tags/components/TagSelect/TagSelect';
import { Textarea } from 'components/Textarea/Textarea';

import styles from './MediaOverlay.module.scss';

const c = classNames.bind(styles);

type FileFieldValues = { description: string };

type ControlsProps = {
  onRequestClose: () => void;
};

/** @deprecated */
export function Controls({ onRequestClose }: ControlsProps) {
  const { applicationID } = useParams<ApplicationParams>();

  const { file } = useMedia();

  const {
    formState: { isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<FileFieldValues>({
    defaultValues: { description: file?.description },
  });

  const { mutate: mutateFile, isLoading: isUpdating } = useUpdateFile({
    onSuccess(file) {
      setIsSuccess(true);
      reset({ description: file?.description });
    },
  });
  const { download } = useDownloadFile();

  const [isSuccess, setIsSuccess] = useSpringState(false, 2000);

  const { mutate: deleteFiles, isLoading: isDeleting } = useDeleteFiles({
    onSuccess() {
      onRequestClose();
    },
  });

  const { mutate: addTags } = useAddEntityTags({
    entity: 'files',
    listQueryKey: ['files'],
    singleQueryKey: ['file', file?.id, applicationID],
  });

  const { mutate: removeTags } = useDeleteEntityTags({
    entity: 'files',
    listQueryKey: ['files'],
    singleQueryKey: ['file', file?.id, applicationID],
  });

  function onSubmit({ description }: FileFieldValues) {
    if (!file) {
      return;
    }

    const updatedFile = { ...file, description };

    mutateFile({
      fileId: updatedFile.id,
      description,
      optimisticUpdateData: updatedFile,
    });
  }

  function handleDownloadFile() {
    if (!file) {
      return;
    }

    download(file?.id);
  }

  function handleDeleteFile() {
    if (!file) {
      return;
    }

    addDeleteConfirmationDialog({
      title: 'Are you sure you want to delete this file?',
      onConfirm: () => deleteFiles({ file_ids: [file.id] }),
      isLoading: isDeleting,
    });
  }

  function handleAddTags(tag: Tag) {
    if (!file) {
      return;
    }

    addTags({ id: file.id, tagIds: [tag.id] });
  }

  function handleRemoveTags(tagId: Tag['id']) {
    if (!file) {
      return;
    }

    removeTags({ id: file.id, tagIds: [tagId] });
  }

  return (
    <div className={c('controls')}>
      <IconButton
        variant="ghost"
        icon="cancel"
        label="Close"
        className={c('close')}
        onClick={onRequestClose}
      />

      {!file ? (
        <Loader text="Loading file details..." />
      ) : (
        <>
          <Heading className={c('heading')} level="2">
            {file.name}
          </Heading>
          <TagSelect
            id={`file_${file.id}_tags`}
            defaultSelection={file.tags}
            className={c('tags')}
            trigger={
              <Button size="xsmall" variant="secondary">
                Edit labels
              </Button>
            }
            onAdd={handleAddTags}
            onRemove={handleRemoveTags}
          />

          <ButtonGroup>
            <IconButton
              icon="download"
              variant="secondary"
              label="Download"
              onClick={handleDownloadFile}
              size="small"
              showLabel
            />
            <IconButton
              icon="delete"
              variant="secondary"
              label="Delete"
              loading={isDeleting}
              onClick={handleDeleteFile}
              size="small"
              showLabel
            />
          </ButtonGroup>

          <Separator />

          <form onSubmit={handleSubmit(onSubmit)}>
            <Textarea {...register('description')} />

            <ButtonGroup>
              <Button
                variant="primary"
                type="submit"
                loading={isUpdating}
                disabled={!isDirty}
              >
                Save description
              </Button>

              {isDirty && (
                <InlineNotification>Unsaved changes</InlineNotification>
              )}

              {isSuccess && (
                <InlineNotification intent="success">Saved!</InlineNotification>
              )}
            </ButtonGroup>
          </form>
        </>
      )}
    </div>
  );
}
