import React from 'react';
import classNames from 'classnames/bind';
import { DatesRangeValue } from '@mantine/dates';
import { MultiValue } from 'react-select';
import { Skeleton } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

import Camera from 'types/camera';
import Gateway from 'types/gateway';
import Stream, { StreamType } from 'types/stream';
import {
  FilesSearchEntity,
  useFilesSearch,
  UseFilesSearchParams,
} from 'files/hooks/useFilesSearch';
import { LumeoFile } from 'types/file';
import {
  getPresetDateRange,
  RelativeDateRange,
} from 'dashboards/services/time_range';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useFiles } from 'files/hooks/useFiles';
import { useStream } from 'streams/hooks/useStream';

import { Accordion } from 'components/Accordion/Accordion';
import { CameraSelect } from 'cameras/components/CameraSelect/CameraSelect';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { DateRangePickerInput } from 'components/DateRangePickerInput/DateRangePickerInput';
import { Drawer, DrawerProps } from 'components/Drawer/Drawer';
import { Field } from 'components/Field/Field';
import { FilesSearchUpgradeNotice } from 'files/views/Search/UpgradeNotice';
import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';
import { Input } from 'components/Input/Input';
import { OptionalTooltip } from 'components/Tooltip/Tooltip';
import { Radio, RadioOption } from 'components/Radio/Radio';
import { StreamSelect } from 'streams/components/StreamSelect/StreamSelect';

import { FilesSearchConfidenceSlider } from '../FilesSearchConfidenceSlider/FilesSearchConfidenceSlider';
import { FilesSearchDetail } from './FilesSearchDetail';
import { FilesSearchDrawerList } from './FilesSearchDrawerList';
import styles from './FilesSearchDrawer.module.scss';

const c = classNames.bind(styles);

const limit = 150;
const defaultDateRange: RelativeDateRange = 'TODAY';

type FilesSearchDrawerProps = { initialStreamId?: string } & DrawerProps;

export function FilesSearchDrawer({
  open,
  onClose,
  initialStreamId,
}: FilesSearchDrawerProps) {
  const { data: currentPlan } = useCurrentPlan();

  const [filters, setFilters] = React.useState<
    UseFilesSearchParams & { search_entities: FilesSearchEntity[] }
  >(() => {
    const [since, until] = getPresetDateRange(defaultDateRange);

    return {
      page: 1,
      created_ts_since: since.toISOString(),
      created_ts_until: until.toISOString(),
      confidence: 0.5,
      search_entities: ['events', 'objects', 'texts', 'vectors'],
      stream_ids: initialStreamId ? [initialStreamId] : undefined,
      sort_by: 'created_at',
      limit,
    };
  });
  const [debouncedFilters] = useDebouncedValue(filters, 200);

  const isSearching = Boolean(filters.query);

  const filesSearchQueryResult = useFilesSearch(debouncedFilters, {
    keepPreviousData: true,
    enabled: isSearching && currentPlan?.can_search,
  });

  const filesQueryResult = useFiles(
    ['files'],
    { ...debouncedFilters, with_thumbnail: true, include_snapshots: false },
    {
      keepPreviousData: true,
      enabled: !isSearching,
    }
  );

  const { data: stream, isInitialLoading: isLoadingStream } =
    useStream(initialStreamId);

  const [selectedFile, setSelectedFile] =
    React.useState<Pick<LumeoFile, 'id' | 'name'>>();

  function handleInputChange({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setFilters(({ query, ...previousFilters }) => {
      return value
        ? { ...previousFilters, query: value, sort_by: 'relevance', page: 1 }
        : { ...previousFilters, sort_by: 'created_at', page: 1 };
    });
  }

  function handlePageChange(page: number) {
    setFilters((previousFilters) => ({ ...previousFilters, page }));
  }

  function handleDateRangeChange(dateRange?: DatesRangeValue) {
    if (!dateRange) {
      return;
    }

    const [since, until] = dateRange;

    if (!since || !until) {
      return;
    }

    setFilters((previousFilters) => ({
      ...previousFilters,
      page: 1,
      created_ts_since: since.toISOString(),
      created_ts_until: until.toISOString(),
    }));
  }

  function handleConfidenceChange(confidence: number) {
    setFilters((previousFilters) => ({
      ...previousFilters,
      page: 1,
      confidence,
    }));
  }

  function handleStreamsChange(streams: MultiValue<Stream>) {
    setFilters((previousFilters) => ({
      ...previousFilters,
      page: 1,
      stream_ids: streams.map(({ id }) => id),
    }));
  }

  function handleCamerasChange(cameras: MultiValue<Camera>) {
    setFilters((previousFilters) => ({
      ...previousFilters,
      camera_ids: cameras.map(({ id }) => id),
      page: 1,
    }));
  }

  function handleGatewaysChange(gateways: MultiValue<Gateway>) {
    setFilters((previousFilters) => ({
      ...previousFilters,
      page: 1,
      gateway_ids: gateways.map(({ id }) => id),
    }));
  }

  function handleSearchEntitiesChange({
    target: { checked, value },
  }: React.ChangeEvent<HTMLInputElement> & {
    target: { value: FilesSearchEntity };
  }) {
    if (checked) {
      setFilters(({ confidence, search_entities, ...previousFilters }) => ({
        ...previousFilters,
        page: 1,
        search_entities: Array.from(new Set([...search_entities, value])),
      }));
    } else {
      setFilters(({ confidence, search_entities, ...previousFilters }) => {
        const newSearchEntities = [...search_entities].filter(
          (entity) => entity !== value
        );

        return {
          ...previousFilters,
          page: 1,
          search_entities: newSearchEntities,
          // Remove confidence if vectors filter has been removed
          confidence: newSearchEntities.includes('vectors')
            ? confidence
            : undefined,
        };
      });
    }
  }

  function handleSortChange(sort: string) {
    if (sort === 'created_at' || sort === 'relevance') {
      setFilters((previousFilters) => ({
        ...previousFilters,
        page: 1,
        sort_by: sort,
      }));
    }
  }

  const searchEntities = filters.search_entities;
  const isConfidenceSliderDisabled = !Boolean(
    searchEntities.length && searchEntities.includes('vectors')
  );

  const canSearch = currentPlan && currentPlan.can_search;
  const shouldShowUpgradeNotice = !canSearch && isSearching;

  const queryResult =
    canSearch && isSearching ? filesSearchQueryResult : filesQueryResult;

  return (
    <Drawer
      classNames={{
        root: c('drawer'),
        body: c('drawer-body'),
        content: c('drawer-content'),
      }}
      containerClassName={c('drawer-container')}
      open={open}
      onClose={onClose}
    >
      <div className={c('list')}>
        <div className={c('controls')}>
          <DateRangePickerInput
            className={c('input')}
            defaultValue={defaultDateRange}
            onChange={handleDateRangeChange}
            disabled={shouldShowUpgradeNotice}
            allowSingleDateInRange
          />
          <Input
            icon="search"
            value={filters.query}
            className={c('input')}
            size="small"
            onChange={handleInputChange}
            placeholder="Search..."
          />
          {isLoadingStream ? (
            <Skeleton height="var(--size-xl)" width="100%" />
          ) : (
            <StreamSelect
              className={c('input')}
              size="small"
              defaultValue={stream}
              isLoading={isLoadingStream}
              onChange={handleStreamsChange}
              queryFilters={{
                sources: ['camera_stream', 'uri_stream'],
                stream_types: [StreamType.FILE, StreamType.RTSP],
              }}
              isDisabled={shouldShowUpgradeNotice}
              isMulti
            />
          )}
          <OptionalTooltip
            showTooltip={isConfidenceSliderDisabled}
            content="Enable scenes search to set confidence"
          >
            <FilesSearchConfidenceSlider
              className={c('input')}
              onChangeEnd={handleConfidenceChange}
              disabled={
                isConfidenceSliderDisabled ||
                !isSearching ||
                shouldShowUpgradeNotice
              }
            />
          </OptionalTooltip>

          <Accordion
            className={c('input')}
            variant="flat"
            level="4"
            label="More filters"
          >
            <div className={c('selects')}>
              <CameraSelect
                size="small"
                onChange={handleCamerasChange}
                isDisabled={shouldShowUpgradeNotice}
                isMulti
              />
              <GatewaySelect
                size="small"
                onChange={handleGatewaysChange}
                isDisabled={shouldShowUpgradeNotice}
                isMulti
              />
            </div>

            <Field className={c('field')} label="Search in">
              <div className={c('search-entities')}>
                <Checkbox
                  id="search_entities_objects"
                  name="search_entities"
                  value="objects"
                  label="Objects"
                  checked={searchEntities.includes('objects')}
                  onChange={handleSearchEntitiesChange}
                  disabled={!isSearching || shouldShowUpgradeNotice}
                />
                <Checkbox
                  id="search_entities_events"
                  name="search_entities"
                  value="events"
                  label="Events"
                  checked={searchEntities.includes('events')}
                  onChange={handleSearchEntitiesChange}
                  disabled={!isSearching || shouldShowUpgradeNotice}
                />
                <Checkbox
                  id="search_entities_texts"
                  name="search_entities"
                  value="texts"
                  label="Text"
                  checked={searchEntities.includes('texts')}
                  onChange={handleSearchEntitiesChange}
                  disabled={!isSearching || shouldShowUpgradeNotice}
                />
                <Checkbox
                  id="search_entities_vectors"
                  name="search_entities"
                  value="vectors"
                  label="Scenes"
                  checked={searchEntities.includes('vectors')}
                  onChange={handleSearchEntitiesChange}
                  disabled={!isSearching || shouldShowUpgradeNotice}
                />
              </div>
            </Field>
            <Field className={c('field')} label="Sort by">
              <Radio
                id="search_sort"
                value={filters.sort_by}
                onChange={(value) =>
                  value && typeof value === 'string' && handleSortChange(value)
                }
                disabled={!isSearching || shouldShowUpgradeNotice}
              >
                <RadioOption value="relevance">Relevance</RadioOption>
                <RadioOption value="created_at">Date</RadioOption>
              </Radio>
            </Field>
          </Accordion>
        </div>

        {!shouldShowUpgradeNotice && (
          <FilesSearchDrawerList
            queryResult={queryResult}
            page={filters.page}
            onPageChange={handlePageChange}
            onFileClick={setSelectedFile}
            selectedFile={selectedFile}
          />
        )}
      </div>

      <div className={c('detail')}>
        {shouldShowUpgradeNotice && (
          <div className={c('empty')}>
            <FilesSearchUpgradeNotice
              onCancel={() => {
                setFilters(({ ...previousFilters }) => ({
                  ...previousFilters,
                  query: '',
                  sort_by: 'created_at',
                  page: 1,
                }));
              }}
            />
          </div>
        )}

        {!selectedFile && !shouldShowUpgradeNotice && (
          <div className={c('empty')}>Please select a file from the list.</div>
        )}

        {selectedFile && !shouldShowUpgradeNotice && (
          <FilesSearchDetail key={selectedFile.id} file={selectedFile} />
        )}
      </div>
    </Drawer>
  );
}
