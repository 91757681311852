import React from 'react';
import { CellProps, Column } from 'react-table';

import Stream from 'types/stream';

import { relativeTimeFromNow } from 'services/date';

import { EmptyView } from 'components/EmptyView/EmptyView';
import { NameCell, TableLabelsCell } from 'components/Table/Table';
import {
  PaginatedTable,
  PaginatedTableProps,
} from 'components/Table/PaginatedTable';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useUpdateStream } from 'streams/hooks/useUpdateStream';
import { useDownloadFile } from 'files/hooks/useDownloadFile';
import { useDeleteFileStreams } from 'files/hooks/useDeleteFileStreams';

import { useLightbox } from 'files/context/lightbox';
import { FilesOverviewTableActionsCell } from 'files/views/FilesOverview/Table/ActionsCell';

export type FileTableProps = Pick<
  PaginatedTableProps<Stream>,
  'queryResult' | 'pageSize' | 'page' | 'onPageChange'
>;

export function CameraFileListTable(props: FileTableProps) {
  const { viewMedia } = useLightbox();

  const { mutate: updateStream } = useUpdateStream(['streams']);
  const [requestDelete, { isLoading: isDeleting }] = useDeleteFileStreams();
  const { download, isLoading: isDownloading } = useDownloadFile();

  const { data: currentPlan, isSuccess } = useCurrentPlan();
  const canCreateTags =
    (isSuccess && currentPlan === null) ||
    Boolean(currentPlan?.can_create_tags);

  const [isRenamingFile, setIsRenamingFile] = React.useState<Stream['id']>();

  function handleRowClick(file: Stream) {
    if (file.isViewable) {
      handleViewFileClick(file);
    }
  }

  const handleViewFileClick = React.useCallback(
    (file: Stream) => {
      if (!file.fileID || !file.isViewable) {
        return;
      }

      viewMedia(file.fileID);
    },
    [viewMedia]
  );

  function handleBulkDownload(fileIds: string[]) {
    fileIds.forEach((file) => download(file));
  }

  const columns = React.useMemo(() => {
    const columns: Column<Stream>[] = [
      {
        id: 'name',
        Header: 'File',
        Cell(props: CellProps<Stream>) {
          function handleRename(name: string) {
            updateStream(props.row.original.withName(name));
            setIsRenamingFile(undefined);
          }

          function cancelRename() {
            setIsRenamingFile(undefined);
          }

          return (
            <NameCell
              {...props}
              isRenaming={isRenamingFile === props.row.original.id}
              onNameChange={handleRename}
              onCancel={cancelRename}
              entity="file"
            />
          );
        },
      },
    ];

    if (canCreateTags) {
      columns.push({
        id: 'tags',
        Header: 'Labels',
        Cell(props: CellProps<Stream>) {
          return (
            <TableLabelsCell
              {...props}
              entity="streams"
              listQueryKey={['streams']}
              singleQueryKey={['stream']}
            />
          );
        },
      });
    }

    columns.push(
      {
        id: 'updated_at',
        Header: 'Uploaded',
        sortType: 'date',
        accessor(file: Stream) {
          return relativeTimeFromNow(file.updated_at);
        },
      },
      {
        id: 'actions',
        Header: ' ',
        width: 0,
        Cell(props: CellProps<Stream>) {
          return (
            <FilesOverviewTableActionsCell
              file={props.row.original}
              onViewClick={handleViewFileClick}
              onRenameClick={setIsRenamingFile}
            />
          );
        },
      }
    );

    return columns;
  }, [canCreateTags, isRenamingFile, updateStream, handleViewFileClick]);

  return (
    <PaginatedTable
      {...props}
      id="camera-files-table"
      label="camera files"
      columns={columns}
      onRowClick={handleRowClick}
      emptyMessage={
        <EmptyView>
          This camera has not uploaded Universal Bridge clips yet.
        </EmptyView>
      }
      bulkActions={[
        {
          action: 'Download',
          loading: isDownloading,
          onClick: handleBulkDownload,
        },
        {
          action: 'Delete',
          icon: 'delete',
          intent: 'danger',
          loading: isDeleting,
          onClick: requestDelete,
        },
      ]}
    />
  );
}
