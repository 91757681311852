import { QueryKey, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Tag from 'types/tag';
import { TagPaginatedAPIResponse } from 'types/api';

import { useAPI } from 'hooks/api/useAPI';
import {
  useBillingMutation,
  UseBillingMutationOptions,
} from 'hooks/api/useBillingMutation';

export type UseCreateTagOptions = Omit<
  UseBillingMutationOptions<Tag, APIError, Tag>,
  'action' | 'entity'
>;

type UseCreateTagContext = {
  previousTags: [QueryKey, TagPaginatedAPIResponse | undefined][];
};

export function useCreateTag({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseCreateTagOptions = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useBillingMutation<Tag, APIError, Tag, UseCreateTagContext>(
    api.tags.create,
    {
      ...options,
      action: 'create',
      entity: 'tag',
      async onMutate(tag) {
        await queryClient.cancelQueries(['tags']);
        const tagsQueries = queryClient.getQueriesData<TagPaginatedAPIResponse>(
          ['tags']
        );

        const firstPage = tagsQueries.find(
          ([, data]) => data && data.current_page === 1
        );

        if (firstPage) {
          const [queryKey, queryData] = firstPage;
          if (queryData) {
            queryClient.setQueryData(queryKey, {
              ...queryData,
              data: [tag, ...queryData.data],
            });
          }
        }

        onMutate?.(tag);

        return {
          previousTags: tagsQueries,
        };
      },
      async onError(error, tag, context) {
        onError?.(error, tag, context);

        if (context) {
          context.previousTags.forEach(([queryKey, queryData]) =>
            queryClient.setQueryData(queryKey, queryData)
          );
        }
      },
      async onSettled(...args) {
        queryClient.invalidateQueries(['tags']);
        onSettled?.(...args);
      },
    }
  );
}
