import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import * as RadixDialog from '@radix-ui/react-dialog';

import { useAppRedirect } from 'hooks/useAppRedirect';

import { Controls } from './Controls';
import { Preview } from './Preview';
import styles from './Lightbox.module.scss';

const c = classNames.bind(styles);

export type ContentProps = Pick<
  RadixDialog.DialogProps,
  'open' | 'onOpenChange' | 'defaultOpen'
>;

/** @deprecated */
function Content({ onOpenChange, ...props }: ContentProps) {
  const redirect = useAppRedirect();
  const { pathname } = useLocation();

  function handleOpenChange(isOpen: boolean) {
    onOpenChange?.(isOpen);

    // FIXME(dustin): This is a workaround for a current bug in the Lightbox component
    // it looks like there is a functionality in the file lightbox to share links to
    // files. That would make it necessary to present a link of that file to the user
    // possibly change the url to `...deploy/files/:fileID` in order to copy&paste and
    // share the link. That is not happenning in the current version of the app. It
    // appears that this code is removing the `.../:fileID` part from the url so the
    // lightbox does not reapear if the browser windows is refocused.
    // What this workaround tries to mitigate is the ability to use the lightbox from
    // other locations than the `/deploy/files` page. We currently also use it in the
    // camera detail view under the `files` tab. Closing the lightbox from the camera
    // would redirect to the `/deploy/files` page, which is not the desired behavior.
    // I guess a desired fix could be to either go back in history (in case of the
    // camera detail view) or to redirect to the `/deploy/files` page if there is no
    // history. Either way, the fix should also "reactivate" the change of the url to
    // `/deploy/files:fileID` in the first place. This is not happening currently and
    // is the reason why this current workaround works.
    // https://app.shortcut.com/lumeo/story/9277/fix-the-share-file-stream-functionality
    if (!isOpen && pathname.includes('/deploy/files')) {
      redirect('/deploy/files');
    }
  }

  return (
    <RadixDialog.Root {...props} onOpenChange={handleOpenChange}>
      <RadixDialog.Portal>
        <RadixDialog.Overlay className={c('overlay')} />
        <RadixDialog.Content className={c('content')}>
          <Preview />
          <Controls />
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}

/** @deprecated */
const Memoized = React.memo(Content);

export { Memoized as Content };
