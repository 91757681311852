import React from 'react';
import { NodeModel } from '@minoru/react-dnd-treeview';

import Tag from 'types/tag';

export function useTagsTreeStructure(
  tags?: Tag[],
  includeGenericOptions: boolean = false
) {
  const [tree, setTree] = React.useState<NodeModel<Tag>[] | null>(null);

  React.useEffect(() => {
    if (!tags) {
      setTree(null);
      return;
    }

    const transformedTags = tags
      .map((tag) => ({
        id: tag.id,
        text: tag.name,
        parent: tag.parent ?? 0,
        droppable: true,
        data: tag,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));

    setTree([
      // Enable `droppable: true` here to fix sorting in the tree view
      // drag & drop is disabled manually in <TagsTree /> via `canDrop`
      // and `canDrag` props
      ...(includeGenericOptions
        ? [{ id: 'all', parent: 0, droppable: true, text: 'All' }]
        : []),
      ...transformedTags,
      ...(includeGenericOptions
        ? [{ id: 'none', parent: 0, droppable: true, text: 'Unlabeled' }]
        : []),
    ]);
  }, [tags, includeGenericOptions]);

  return tree;
}
