import React from 'react';

import { LumeoFile } from 'types/file';

export type LightboxContextProps = {
  file?: LumeoFile;
  url: string | undefined;
  mimetype: string | null | undefined;
  isLoading: boolean;
  isDeleting: boolean;
  canDisplayMedia: boolean;
  viewMedia: (fileId: string) => void;
  download: () => void;
  deleteFile: () => void;
};

export const LightboxContext = React.createContext<
  LightboxContextProps | undefined
>(undefined);

/** @deprecated */
export function useLightbox() {
  const context = React.useContext(LightboxContext);

  if (context === undefined) {
    throw new Error(
      'Hook `useLightbox` may only be used within a <LightboxContent.Provider />.'
    );
  }

  return context;
}
