import React from 'react';
import classnames from 'classnames/bind';

import Stream from 'types/stream';
import { NavRoute } from 'application/types/routes';

import {
  StatusIndicator,
  Status,
} from 'components/StatusIndicator/StatusIndicator';
import { TextAppLink } from 'components/TextAppLink/TextAppLink';

import { EntityListObject, EntityListEntities } from './types';
import styles from './EntityList.module.scss';

const c = classnames.bind(styles);

export type EntityListLinkProps<T extends EntityListObject> = {
  entity: T;
  status?: Status;
  type?: EntityListEntities;
};

const ROUTE_SECTION_MAP: Record<EntityListEntities, NavRoute> = {
  pipelines: NavRoute.DESIGN,
  models: NavRoute.DESIGN,
  cameras: NavRoute.DEPLOY,
  sources: NavRoute.DEPLOY,
  deployments: NavRoute.DEPLOY,
  files: NavRoute.DEPLOY,
  gateways: NavRoute.DEPLOY,
  streams: NavRoute.DEPLOY,
};

export function EntityListLink<T extends EntityListObject>({
  entity,
  type,
  status,
}: EntityListLinkProps<T>) {
  const { id, name, type: entityType } = entity;
  let targetId: string | null | undefined = id;
  let slug: string | undefined = entityType || type;

  // FIXME: checking for `sources` here worked, but fix was only found empirically
  if ((slug === 'streams' || slug === 'sources') && type === 'sources') {
    if (
      'source' in entity &&
      (entity as unknown as Stream).source === 'camera_stream'
    ) {
      slug = 'cameras';
      targetId = (entity as unknown as Stream).camera_id;
    } else {
      slug = 'streams';
    }
  }

  if (!targetId) {
    return <p className={c('item-value-unknown')}>Deleted {type}</p>;
  }

  const section = type ? `/${ROUTE_SECTION_MAP[type]}` : '';

  return (
    <TextAppLink to={`${section}/${slug}/${targetId}`}>
      <span>{name || targetId}</span>
      {status && <StatusIndicator status={status} size="small" />}
    </TextAppLink>
  );
}
