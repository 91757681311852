import React from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import classNames from 'classnames/bind';
import { useForm } from 'react-hook-form';

import Tag from 'types/tag';

import { useAddEntityTags } from 'tags/hooks/useAddEntityTag';
import { useDeleteEntityTags } from 'tags/hooks/useDeleteEntityTags';
import { useLightbox } from 'files/context/lightbox';
import { useSpringState } from 'hooks/useSpringState';
import { useUpdateFile } from 'files/hooks/useUpdateFile';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Loader } from 'components/Loader/Loader';
import { Separator } from 'components/Separator/Separator';
import { TagSelect } from 'tags/components/TagSelect/TagSelect';
import { Textarea } from 'components/Textarea/Textarea';

import styles from './Lightbox.module.scss';

const c = classNames.bind(styles);

type FileFieldValues = { description: string };

/** @deprecated */
export function Controls() {
  const {
    file,
    isLoading,
    isDeleting,
    download: downloadFile,
    deleteFile,
  } = useLightbox();

  const {
    formState: { isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<FileFieldValues>({
    defaultValues: { description: file?.description },
  });

  const { mutate: mutateFile, isLoading: isUpdating } = useUpdateFile({
    onSuccess(file) {
      setIsSuccess(true);
      reset({ description: file?.description });
    },
  });

  const [isSuccess, setIsSuccess] = useSpringState(false, 2000);

  const { mutate: addTags } = useAddEntityTags({
    entity: 'files',
    listQueryKey: ['files'],
    singleQueryKey: ['file'],
  });

  const { mutate: removeTags } = useDeleteEntityTags({
    entity: 'files',
    listQueryKey: ['files'],
    singleQueryKey: ['file'],
  });

  const fileDescription = file?.description;

  React.useEffect(() => {
    if (!fileDescription || isDirty) {
      return;
    }

    reset({ description: fileDescription });
  }, [fileDescription, isDirty, reset]);

  function onSubmit({ description }: FileFieldValues) {
    if (!file) {
      return;
    }

    const updatedFile = { ...file, description };

    mutateFile({
      fileId: updatedFile.id,
      description,
      optimisticUpdateData: updatedFile,
    });
  }

  // Files overview are streams with StreamType.FILE therefore
  // tags are added to the stream
  function handleAddTags(tag: Tag) {
    if (!file) {
      return;
    }

    addTags({ id: file.id, tagIds: [tag.id] });
  }

  // Files overview are streams with StreamType.FILE therefore
  // tags are removed from the stream
  function handleRemoveTags(tagId: Tag['id']) {
    if (!file) {
      return;
    }

    removeTags({ id: file.id, tagIds: [tagId] });
  }

  return (
    <div className={c('controls')}>
      <RadixDialog.Close asChild>
        <IconButton
          variant="ghost"
          icon="cancel"
          label="Close"
          className={c('close')}
        />
      </RadixDialog.Close>

      {isLoading && <Loader text="Loading file details..." />}

      {file && (
        <>
          <Heading className={c('heading')} level="2">
            {file.name}
          </Heading>
          <TagSelect
            id={`file_${file.id}_tags`}
            defaultSelection={file.tags}
            className={c('tags')}
            trigger={
              <Button size="xsmall" variant="secondary">
                Edit labels
              </Button>
            }
            onAdd={handleAddTags}
            onRemove={handleRemoveTags}
          />
        </>
      )}

      <ButtonGroup>
        {file && (
          <IconButton
            icon="download"
            variant="secondary"
            label="Download"
            onClick={downloadFile}
            size="small"
            showLabel
          />
        )}
        <IconButton
          icon="delete"
          variant="secondary"
          label="Delete"
          loading={isDeleting}
          onClick={deleteFile}
          size="small"
          showLabel
        />
      </ButtonGroup>

      <Separator />

      <form onSubmit={handleSubmit(onSubmit)}>
        {!file && (
          <FormMessage icon="info">
            Descriptions are not supported for external files.
          </FormMessage>
        )}

        <Textarea disabled={isLoading || !file} {...register('description')} />

        <ButtonGroup>
          <Button
            variant="primary"
            type="submit"
            loading={isUpdating}
            disabled={!isDirty || !file}
          >
            Save description
          </Button>

          {isDirty && <InlineNotification>Unsaved changes</InlineNotification>}

          {isSuccess && (
            <InlineNotification intent="success">Saved!</InlineNotification>
          )}
        </ButtonGroup>
      </form>
    </div>
  );
}
