import React from 'react';
import classnames from 'classnames/bind';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import Camera from 'types/camera';
import Deployment from 'types/deployment';
import Pipeline from 'types/pipeline';
import Stream from 'types/stream';
import { LumeoFile } from 'types/file';

import { USER_TIMEZONE } from 'services/timezone';
import { truncateMiddle } from 'services/string';
import { useMediaContextFile } from 'files/services/useMediaContextFile';

import { AppLink } from 'components/AppLink/AppLink';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Loader } from 'components/Loader/Loader';
import { OptionalTooltip } from 'components/Tooltip/OptionalTooltip';
import { TagSelect } from 'tags/components/TagSelect/TagSelect';
import { Text } from 'components/Text/Text';

import styles from './Files.module.scss';

export const c = classnames.bind(styles);

export type FileTableElementProps = {
  file: LumeoFile;
  deployment?: Deployment;
  isLoadingDeployment: boolean;
  pipeline?: Pipeline;
  isLoadingPipeline: boolean;
  camera?: Camera;
  isLoadingCamera: boolean;
  stream?: Stream;
  isLoadingStream: boolean;
};

export function DashboardFilesElement({
  file,
  deployment,
  isLoadingDeployment,
  pipeline,
  isLoadingPipeline,
  camera,
  isLoadingCamera,
  stream,
  isLoadingStream,
}: FileTableElementProps) {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const timezone = params.get('timezone');

  const { viewFile, isInitialLoading, isDisabled } = useMediaContextFile(file);

  const fileCreationDate = dayjs(file.created_at).tz(timezone ?? USER_TIMEZONE);
  const displayDate = fileCreationDate.format('MMM D, YYYY h:mma');

  function onRowClick() {
    if (isDisabled) {
      return;
    }
    viewFile();
  }

  return (
    <div onClick={onRowClick} className={c('list-item')}>
      <div>
        <div className={c('list-item-header')}>
          <Heading level="4">
            <OptionalTooltip
              showTooltip={file ? file.name.length > 64 : false}
              content={file ? file.name : ''}
            >
              {truncateMiddle(file.name || 'Unknown', 64)}
            </OptionalTooltip>
          </Heading>
          <Text size="small" inline>
            {displayDate}
          </Text>
        </div>
        <div className={c('list-item-entities')}>
          {(file.camera_id || file.stream_id) && (
            <div className={c('list-item-entity')}>
              <Icon name="source" size="small" />

              {file.camera_id && isLoadingCamera && (
                <span aria-hidden="true" className="skeleton-text" />
              )}

              {file.stream_id && isLoadingStream && (
                <span aria-hidden="true" className="skeleton-text" />
              )}

              {camera && (
                <OptionalTooltip
                  showTooltip={camera ? camera.name.length > 32 : false}
                  content={camera ? camera.name : ''}
                >
                  <AppLink
                    className={c('list-item-link')}
                    to={`/deploy/cameras/${camera?.id}`}
                  >
                    {truncateMiddle(camera.name || 'Unknown', 32)}
                  </AppLink>
                </OptionalTooltip>
              )}

              {stream && (
                <OptionalTooltip
                  showTooltip={stream ? stream.name.length > 32 : false}
                  content={stream ? stream.name : ''}
                >
                  <AppLink
                    className={c('list-item-link')}
                    to={`/deploy/streams/${stream?.id}`}
                  >
                    {truncateMiddle(stream.name || 'Unknown', 32)}
                  </AppLink>
                </OptionalTooltip>
              )}

              {!camera && !isLoadingCamera && !stream && !isLoadingStream && (
                <Text>Deleted {file.camera_id ? 'camera' : 'stream'}</Text>
              )}
            </div>
          )}

          <div className={c('list-item-group')}>
            {file.pipeline_id && (
              <div className={c('list-item-entity')}>
                <Icon name="pipeline" size="small" />

                <OptionalTooltip
                  showTooltip={pipeline ? pipeline.name.length > 32 : false}
                  content={pipeline ? pipeline.name : ''}
                >
                  {isLoadingPipeline && (
                    <span aria-hidden="true" className="skeleton-text">
                      &nbsp;
                    </span>
                  )}

                  {pipeline && (
                    <AppLink
                      className={c('list-item-link')}
                      to={`/design/pipelines/${pipeline?.id}`}
                    >
                      {truncateMiddle(pipeline.name || 'Unknown', 32)}
                    </AppLink>
                  )}

                  {!pipeline && !isLoadingPipeline && (
                    <Text>Deleted pipeline</Text>
                  )}
                </OptionalTooltip>
              </div>
            )}

            {file.deployment_id && (
              <div className={c('list-item-entity')}>
                <Icon name="deployment" size="small" />

                <OptionalTooltip
                  showTooltip={
                    deployment?.name ? deployment.name.length > 32 : false
                  }
                  content={deployment ? deployment.name : ''}
                >
                  {isLoadingDeployment && (
                    <span aria-hidden="true" className="skeleton-text">
                      &nbsp;
                    </span>
                  )}

                  {deployment && (
                    <AppLink
                      className={c('list-item-link')}
                      to={`/deploy/deployments/${deployment?.id}`}
                    >
                      {truncateMiddle(deployment.name || 'Unknown', 32)}
                    </AppLink>
                  )}

                  {!deployment && !isLoadingDeployment && (
                    <Text>Deleted deployment</Text>
                  )}
                </OptionalTooltip>
              </div>
            )}
          </div>
          {file?.description && (
            <div className={c('list-item-entity')}>
              <Icon name="meta" size="small" />
              <OptionalTooltip
                showTooltip={file.description.length > 32}
                content={file.description}
              >
                <Text className={c('description')}>{file.description}</Text>
              </OptionalTooltip>
            </div>
          )}
        </div>
        <TagSelect
          id={`file_${file.id}_tags`}
          defaultSelection={file.tags}
          className={c('tags')}
          readOnly
        />
      </div>
      {isInitialLoading && <Loader size="xsmall" text="" />}
    </div>
  );
}
