import React from 'react';
import classnames from 'classnames/bind';
import {
  Drawer as DrawerBase,
  DrawerProps as DrawerBaseProps,
} from '@mantine/core';

import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';

import styles from './Drawer.module.scss';

const c = classnames.bind(styles);

export type DrawerProps = React.PropsWithChildren<{
  containerClassName?: string;
  open?: boolean;
  header?: React.ReactNode;
  title?: string;
  footer?: React.ReactNode;
  onClose?: () => void;
}> &
  Pick<
    DrawerBaseProps,
    'id' | 'className' | 'classNames' | 'closeOnEscape' | 'closeOnClickOutside'
  >;

/**
 * Modal that slides in from the right
 */
export function Drawer({
  containerClassName,
  classNames,
  open = false,
  children,
  title,
  header,
  footer,
  onClose,
  ...props
}: DrawerProps) {
  function handleClose() {
    onClose?.();
  }

  return (
    <DrawerBase
      {...props}
      opened={open}
      onClose={handleClose}
      classNames={{
        ...classNames,
        overlay: c('overlay', classNames?.overlay),
        inner: c('drawer', classNames?.inner, { hasFooter: footer }),
        content: c('drawer-content', classNames?.content),
        body: c('body', classNames?.body),
        title: c('title', classNames?.title),
      }}
      padding={0}
      position="right"
      withCloseButton={false}
    >
      <div className={c('header', { container: title || header })}>
        {title && <Heading level="3">{title}</Heading>}
        {header}
        <IconButton
          className={c('header-close')}
          icon="cancel"
          onClick={onClose}
          label="Close"
        />
      </div>

      <div className={c('container', 'content', containerClassName)}>
        {children}
      </div>
      {footer && <div className={c('footer', 'container')}>{footer}</div>}
    </DrawerBase>
  );
}
