import classnames from 'classnames/bind';

import { Button, ButtonProps } from 'components/Button/Button';
import { Icon, IconType } from 'components/Icon/Icon';

import styles from './BulkManager.module.scss';

const c = classnames.bind(styles);

export type BulkManagerProps = {
  className?: string;
  list: unknown[];
  /**
   * Label in singular
   */
  label?: string;
  onClear: () => void;
  onSelectAll?: () => void;
  actions: {
    label: string;
    loading?: boolean;
    disabled?: boolean;
    intent?: ButtonProps['intent'];
    icon?: IconType;
    onClick: () => void;
  }[];
};

export function BulkManager({
  className,
  list,
  label = 'item',
  onClear,
  onSelectAll,
  actions,
}: BulkManagerProps) {
  if (list.length !== 1) {
    label += 's';
  }

  return (
    <div className={c('wrap', className)}>
      <div className={c('bulk-manager', 'theme dark')}>
        <div className="columns valign-center spaced">
          <p className={c('label')}>
            <strong>{list.length}</strong> {label} selected
          </p>

          {onSelectAll && (
            <Button variant="ghost" onClick={onSelectAll} size="small">
              Select all on this page
            </Button>
          )}

          <Button variant="ghost" onClick={onClear} size="small">
            Clear selection
          </Button>
        </div>

        <div className="columns valign-center spaced">
          {actions.map(({ label, icon, ...props }) => (
            <Button key={label} {...props} size="small">
              {icon && <Icon name={icon} size="small" />}
              <span>{label}</span>
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}
