import classNames from 'classnames/bind';

import { BulkManager } from 'components/BulkManager/BulkManager';

import { useFilesSearchState } from './search_context';
import styles from './Search.module.scss';

const c = classNames.bind(styles);

export function FilesBulkManager() {
  const {
    selectedFiles,
    isDeleting,
    dispatch,
    deleteSelectedFiles,
  } = useFilesSearchState();

  if (selectedFiles.length === 0) {
    return null;
  }

  return (
    <BulkManager
      className={c('bulk-manager')}
      list={selectedFiles}
      label="file"
      onClear={() => dispatch({ type: 'clear' })}
      actions={[
        {
          label: 'Delete',
          icon: 'delete',
          loading: isDeleting,
          intent: 'danger',
          onClick: deleteSelectedFiles,
        },
      ]}
    />
  );
}
