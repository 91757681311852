import { filesize } from 'filesize';

import { LumeoFile } from 'types/file';

import { useFile } from 'files/hooks/useFile';

import { useMedia } from 'services/media_context';
import download from 'services/download_file';

/** @deprecated */
export function useMediaContextFile(file: LumeoFile) {
  const { setViewingFile } = useMedia();
  const {
    data: cloudFile,
    isLoading,
    isInitialLoading,
    isError,
    refetch: getFile,
  } = useFile(file.id, {
    staleTime: Infinity,
    enabled: false,
  });
  const fileSize = filesize(file.size, { base: 2 });

  async function viewFile() {
    if (!cloudFile) {
      const { data } = await getFile();

      if (data?.data_url) {
        setViewingFile(file, data.data_url);
      }

      return;
    }

    if (!cloudFile.data_url) {
      return;
    }

    setViewingFile(file, cloudFile.data_url);
  }

  async function downloadFile() {
    if (!cloudFile) {
      const { data } = await getFile();

      if (data?.data_url) {
        download(file.name, data.data_url);
      }

      return;
    }

    if (!cloudFile.data_url) {
      return;
    }

    download(file.name, cloudFile.data_url);
  }

  const mimeType = cloudFile?.mime_type;

  return {
    fileSize,
    icon: mimeType?.includes('video') ? 'play' : 'view',
    mimetype: mimeType,

    viewFile,
    downloadFile,
    isLoading,
    isInitialLoading,
    isDisabled: isError,
  };
}
